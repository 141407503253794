import { useEffect, useState } from "react";
import PublicLayout from "../layout/PublicLayout";
import { CourseCard } from "../components/CourseCard";
import { ApiConfig } from "../config/Configuration";
import axios from "axios";
import { useLoaderData } from "react-router-dom";
import { Course } from "../models/course";
import Slider from "react-slick";
import { PrimaryButton } from "../components/PrimaryButton";
import { TextDictionary } from "../utils/TextDictionary";

const PublicCourses = () => {
    // Da tipizzare con l'interfaccia dei corsi
    const courses = useLoaderData() as Course[] || null;

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        centerMode: true,
        className: "center",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    return (
        <PublicLayout>
            <div className="flex flex-col">
                <div className="flex flex-col md:flex-row justify-between items-center bg-primary w-full">
                    <div className="max-w-[1400px] flex flex-row w-full items-center mx-auto">
                        <div className="flex flex-col justify-center gap-4 flex-1 w-full h-full">
                            <div className="py-6 md:py-0">
                                <h1 className="text-white text-6xl font-medium mb-4 bodoni">{TextDictionary.ListaCorsi.Titolo}</h1>
                                <p className="text-white text-xl mb-8">{TextDictionary.ListaCorsi.Sottotitolo}</p>
                                {/* <button className="bg-customYellow text-primary flex items-center border-0 text-sm font-medium px-8 py-2 rounded-lg w-fit">Crea il tuo account gratis</button> */}
                                <PrimaryButton text={TextDictionary.ListaCorsi.CreaAccountGratis} onClick={() => { }} />
                            </div>
                        </div>
                        <div className="flex flex-col gap-4 flex-1 w-full h-full justify-center">
                            <div className="grid grid-cols-2 gap-4 py-8">
                                <img src="https://via.placeholder.com/150" alt="placeholder" className="w-full h-36 rounded-lg" />
                                <img src="https://via.placeholder.com/150" alt="placeholder" className="w-full h-36 rounded-lg" />

                                <img src="https://via.placeholder.com/150" alt="placeholder" className="w-full h-36 rounded-lg" />
                                <img src="https://via.placeholder.com/150" alt="placeholder" className="w-full h-36 rounded-lg" />

                                <img src="https://via.placeholder.com/150" alt="placeholder" className="w-full h-36 rounded-lg" />
                                <img src="https://via.placeholder.com/150" alt="placeholder" className="w-full h-36 rounded-lg" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col justify-center items-center w-full py-10 max-w-[1400px] mx-auto">
                    <div className="w-full mb-14">
                        <Slider {...sliderSettings} >
                            {
                                courses.map((course: any) => (
                                    <CourseCard key={course.ID} course={course} inverse />
                                ))
                            }
                        </Slider>
                    </div>
                    <div className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-12 w-full">
                        {
                            courses.map((course: any) => (
                                <CourseCard key={course.ID} course={course} />
                            ))
                        }
                    </div>
                </div>
            </div>
        </PublicLayout>
    );
};

export default PublicCourses;
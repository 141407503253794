import { useNavigate } from "react-router-dom";
import { Course } from "../models/course";
import { PrimaryButton } from "./PrimaryButton";
import { TextDictionary } from "../utils/TextDictionary";

interface CourseCardProps {
    course: Course;
    inverse?: boolean;
}

export const CourseCard = ({ course, inverse }: CourseCardProps) => {
    console.log("------------------course", course);
    const navigate = useNavigate();

    return (
        <div className={`flex flex-col shadow-md rounded-3xl mr-8 ${inverse ? 'bg-primary' : 'bg-[#f3f3f3]'}`}>
            <div className="flex flex-col h-48">
                <img src="https://placehold.co/400" alt="course" className="object-cover w-full h-full rounded-t-3xl" />
            </div>
            <div className="flex flex-col h-full px-8 pt-8">
                <h2 className={`text-2xl font-bold text-primary pb-2 ${inverse ? 'text-white' : 'text-primary'}`}>{course.Title}</h2>
                {/* Aggiungo un divisorio */}
                <div className="border-t-2 my-2 border-customYellow"></div>
                <span
                    dangerouslySetInnerHTML={{ __html: course?.ShortDescription || '' }}
                    className={`text-sm text-gray-500 mb-4 ${inverse ? 'text-white' : 'text-primary'}`}
                />
                <div className="flex flex-row justify-between items-center mt-4">
                    <h3 className={`text-sm ${inverse ? 'text-white' : 'text-gray-700'}`}><span className={`font-medium ${inverse ? 'text-customYellow' : 'text-primary'}`}>{TextDictionary.ListaCorsi.LabelLezioni}</span> {course.VideoNumber}</h3>
                    <h3 className={`text-sm ${inverse ? 'text-white' : 'text-gray-700'}`}><span className={`font-medium ${inverse ? 'text-customYellow' : 'text-primary'}`}>{TextDictionary.ListaCorsi.LabelData}</span> {course.Date}</h3>
                    <h3 className={`text-sm ${inverse ? 'text-white' : 'text-gray-700'}`}><span className={`font-medium ${inverse ? 'text-customYellow' : 'text-primary'}`}>{TextDictionary.ListaCorsi.LabelDurata}</span> 2</h3>
                </div>
            </div>

            <button className="text-primary bg-customYellow rounded-b-[10px] py-4 px-4 mt-4 font-medium" onClick={
                () => {
                    navigate(`/public/courses/${course.ID}`);
                }
            }>{TextDictionary.ListaCorsi.ScopriCorso}</button>
        </div>
    )
}
import { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import DropdownUser from "./DropdownUser";
import { AppConfig } from "../config/Configuration";
import { TextDictionary } from "../utils/TextDictionary";
import { AuthService } from "../auth/sso/auth.service";
import { PrimaryButton } from "./PrimaryButton";

interface HeaderProps {
  isUserSetted: boolean;
  isAdminArea: boolean;
}

const Header = ({ isUserSetted, isAdminArea }: HeaderProps) => {
  const navigate = useNavigate();

  const [coursesDropdownOpen, setCoursesDropdownOpen] = useState(false);

  // gestisco il click outside per chiudere le dropdown
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (coursesDropdownOpen) {
        if (event.target.closest('.coursesDropdown') === null) {
          setCoursesDropdownOpen(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [coursesDropdownOpen]);

  return (
    <header className="sticky top-0 w-full bg-primary border-0 justify-center hidden lg:flex" style={{ zIndex: 999 }}>
      <div className={`h-[100px] flex flex-grow items-center justify-between px-4 py-4 md:px-6 2xl:px-11 ${!isAdminArea && 'max-w-[1400px]'}`}>
        <div className="flex items-center gap-10 cursor-pointer">
          <div className="text-white font-medium text-lg cursor-pointer" onClick={
            () => {
              navigate('/');
            }
          }>
            <img src={AppConfig.brandLogo} alt="logo" className="h-16" />
            {/* <AppConfig.brandLogo className="h-16"/> */}
          </div>
          {
            !isAdminArea ? (
              <>
                <div className="relative">
                  <div className="text-white text-md cursor-pointer" onClick={
                    () => {
                      setCoursesDropdownOpen(!coursesDropdownOpen);
                    }
                  }>
                    {TextDictionary.Header.VoceCorsi}
                  </div>
                  <div className={`coursesDropdown absolute z-10 w-60 overflow-hidden bg-white rounded-md shadow-xl top-[50px]  ${coursesDropdownOpen ? 'block' : 'hidden'}`}>
                    <span className="block px-4 py-2 text-[14px] text-black font-medium">
                      {TextDictionary.Header.LabelCategorie}
                    </span>
                    <span className="block px-4 py-2 text-sm text-black transition-colors duration-200 transform hover:bg-[#e2e2e2]" onClick={() => {
                      console.log('Cliccato sulla categoria Dogana');
                    }}>
                      {TextDictionary.Header.VoceDogana}
                    </span>
                    <span className="block px-4 py-2 text-sm text-black transition-colors duration-200 transform hover:bg-[#e2e2e2]" onClick={() => {
                      console.log('Cliccato sulla categoria Import / Export');
                    }}>
                      {TextDictionary.Header.VoceImportExport}
                    </span>
                    <span className="block px-4 py-2 text-sm text-black transition-colors duration-200 transform hover:bg-[#e2e2e2]" onClick={() => {
                      console.log('Cliccato sulla categoria Trasporti');
                    }}>
                      {TextDictionary.Header.VoceTrasporti}
                    </span>
                    <span className="block px-4 py-2 text-sm text-black transition-colors duration-200 transform hover:bg-[#e2e2e2] border-t border-t-[#e2e2e2]" onClick={() => {
                      navigate('/public/courses');
                    }}>
                      {TextDictionary.Header.VoceVediCorsi}
                    </span>
                  </div>
                </div>
                <div className="text-white text-md cursor-pointer" onClick={
                  () => {
                    navigate('/subscription-info');
                  }
                }>
                  {TextDictionary.Header.VoceScopriAbbonamenti}
                </div>
                <div className="text-white text-md cursor-pointer" onClick={
                  () => {
                    window.open('https://carbognani.srl/international-news', '_blank');
                  }
                }>
                  {TextDictionary.Header.VoceInternationalNews}
                </div>
              </>
            ) : (
              null
            )
          }
        </div>

        {/* Cerca corsi */}
        {
          !isAdminArea ? (
            <>
              <input type="text" placeholder="Cerca corsi" className="px-4 py-1 w-[20rem] rounded-full bg-white text-primary outline-none" onKeyUp={
                (e: any) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    let text = e.target.value;
                    navigate(`/public/courses?search=${text}`);
                  }
                }
              } />
              <div className="flex items-center gap-8">
                {/* Carrello */}
                <Link to="/cart">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1H5L7.68 14.39C7.77144 14.8504 8.02191 15.264 8.38754 15.5583C8.75318 15.8526 9.2107 16.009 9.68 16H19.4C19.8693 16.009 20.3268 15.8526 20.6925 15.5583C21.0581 15.264 21.3086 14.8504 21.4 14.39L23 6H6M10 21C10 21.5523 9.55229 22 9 22C8.44772 22 8 21.5523 8 21C8 20.4477 8.44772 20 9 20C9.55229 20 10 20.4477 10 21ZM21 21C21 21.5523 20.5523 22 20 22C19.4477 22 19 21.5523 19 21C19 20.4477 19.4477 20 20 20C20.5523 20 21 20.4477 21 21Z" stroke="#F3F3F3" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </Link>

                {/* Se non sono loggato, mostro un pulsante per il login e uno per la registrazione, senno mostro il dropdown */}
                {
                  !AuthService.getInstance().accessToken ? (
                    <>
                      <Link to="/signin" className="text-white font-medium text-md cursor-pointer">
                        {TextDictionary.Header.VoceAccedi}
                      </Link>
                      <PrimaryButton
                        text={TextDictionary.Header.VoceRegistrati}
                        onClick={
                          () => {
                            navigate('/signup');
                          }
                        }
                      />
                  </>
                  ) : (
                    <DropdownUser />
                  )
                }
              </div>
            </>
          ) : (
            null
          )
        }
      </div>
    </header>
  );
};

export default Header;

import { useEffect, useState } from "react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { Course } from "../models/course";
import { Video } from "../models/video";
import { axiosAuthInstance } from "../auth/sso/auth.interceptor";
import { ApiConfig } from "../config/Configuration";
import PublicLayout from "../layout/PublicLayout";
import { PrimaryButton } from "../components/PrimaryButton";
import { TextDictionary } from "../utils/TextDictionary";
import { Resource } from "../models/resource";

export const PublicSingleCourse = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const course = useLoaderData() as Course;

    const [videos, setVideos] = useState<Video[]>();
    const [resources, setResources] = useState<Resource[]>();

    useEffect(() => {
        // dentro a course.VideoIDs ci sono gli id dei video, devo fare una chiamata per ogni id per ottenere i video
        const fetchVideos = async () => {
            const videos = await Promise.all(course.VideoIDs!.map(async (id) => {
                const response = await axiosAuthInstance.get(`${ApiConfig.ROOT_URL}/videos/${id}`);
                return response.data;
            }));

            setVideos(videos);
        }

        const fetchResources = async () => {
            const resources = await Promise.all(course.ResourceIDs!.map(async (id) => {
                const response = await axiosAuthInstance.get(`${ApiConfig.ROOT_URL}/resources/${id}`);
                return response.data;
            }));

            setResources(resources);
        }



        if (course) {
            fetchVideos();
            fetchResources();
        }
    }, [course]);

    const formatDate = (date: string) => {
        const d = new Date(date);
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    }

    return (
        <PublicLayout>
            <div className="flex flex-col">
                <div className="flex flex-col md:flex-row justify-between items-center bg-primary w-full py-12 px-8">
                    <div className="max-w-[1400px] flex flex-col-reverse w-full items-center mx-auto gap-20 lg:flex-row">
                        <div className="flex flex-col justify-center gap-4 flex-1 w-full h-full">
                            <div className="py-6 md:py-0 h-full">
                                <div className="flex flex-col gap-4 mb-8 lg:flex-row">
                                    <span className="text-sm font-medium text-customYellow">{TextDictionary.ListaCorsi.LabelLezioni} <span className="text-white">{videos?.length ?? 0}</span></span>
                                    <span className="text-sm font-medium text-customYellow">{TextDictionary.ListaCorsi.LabelData} <span className="text-white">{formatDate(course?.Date)}</span></span>
                                    <span className="text-sm font-medium text-customYellow">{TextDictionary.ListaCorsi.LabelDurata} <span className="text-white">2h</span></span>
                                </div>

                                <h1 className="text-white text-4xl font-medium mb-4 bodoni">{course?.Title}</h1>
                                <p className="text-white text-xl mb-8">{course?.Teacher}</p>
                                {/* <button className="bg-customYellow text-primary flex items-center border-0 text-sm font-medium px-8 py-2 rounded-lg w-fit">Crea il tuo account gratis</button> */}
                                <p
                                    dangerouslySetInnerHTML={{ __html: course?.ShortDescription || '' }}
                                    className="text-white text-md mb-8 text-opacity-70"
                                />
                                {/* <button className="bg-customYellow text-primary flex items-center border-0 text-sm font-medium px-8 py-2 rounded-lg w-fit">Crea il tuo account gratis</button> */}
                                <div className="flex gap-8">
                                    <PrimaryButton text={TextDictionary.ListaCorsi.CreaAccountGratis} onClick={() => {
                                        console.log('Crea account gratis');
                                    }} inverse />
                                    <PrimaryButton text={TextDictionary.ListaCorsi.BottoneAcquista} onClick={() => {
                                        console.log('Crea account gratis');
                                    }} />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-4 flex-1 w-full h-full justify-center items-center">
                            {
                                course?.EmbeddedPreviewVideoURLHTML && course?.EmbeddedPreviewVideoURLHTML !== '' ? (
                                    <div
                                        dangerouslySetInnerHTML={{ __html: course?.EmbeddedPreviewVideoURLHTML || '' }}
                                        className="w-full flex items-center justify-center mb-8"
                                    />
                                ) : (
                                    <img src="https://placehold.co/400x400" alt="placeholder" className="w-full h-72 object-cover rounded-sm" />
                                )
                            }
                        </div>
                    </div>
                </div>

                <div className="flex flex-col w-full mx-auto lg:flex-row">
                    <div className="w-full flex-1 bg-white px-8 py-12">
                        <h2 className="text-4xl font-bold text-primary bodoni mb-12">{TextDictionary.ListaCorsi.LabelDescrizioneCorso}</h2>
                        <p
                            dangerouslySetInnerHTML={{ __html: course?.Description || '' }}
                            className="text-md text-primary"
                        />
                    </div>
                    <div className="w-full flex-1 bg-secondary px-8 py-12">
                        <h2 className="text-4xl font-bold text-primary bodoni mb-12">{TextDictionary.ListaCorsi.LabelCosaComprende}</h2>
                        {
                            resources?.map((resource) => (
                                <div key={resource.ID} className="flex flex-col gap-4 rounded-lg pb-8">
                                    <h3 className="text-xl font-medium text-primary cursor-pointer hover:underline">{resource.Title}</h3>
                                    <p className="text-md text-primary">{resource.Description}</p>
                                    <a href={resource.URI} target="_blank" className="text-md text-primary underline">{resource.URI}</a>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className="flex flex-col w-full mx-auto bg-primary">
                    <div className="w-full px-8 py-12">
                        <h2 className="text-4xl font-bold text-customYellow bodoni mb-12">{TextDictionary.ListaCorsi.LabelLezioniNelCorso}</h2>
                        {
                            videos?.map((video) => (
                                <div key={video.ID} className="flex flex-col gap-4 rounded-lg pb-8">
                                    <h3 className="text-xl font-medium text-white cursor-pointer hover:underline"
                                        onClick={() => {
                                            navigate(`/public/courses/${course.ID}/videos/${video.ID}`);
                                        }}>{video.Title}</h3>
                                    <p className="text-md text-white">{video.Description}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </PublicLayout>
    )
}
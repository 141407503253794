import axios from 'axios';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { ApiConfig } from '../config/Configuration';
import { TableComponent } from './TableComponent';
import { createGrid, GridApi, GridOptions } from 'ag-grid-community';
import { useNavigate } from 'react-router-dom';
import { TextDictionary } from '../utils/TextDictionary';
import { UseFormSetValue } from 'react-hook-form';
import { axiosAuthInstance } from '../auth/sso/auth.interceptor';

interface RelationHandlerComponentProps {
    options: Options;
    setValue?: UseFormSetValue<any>
}

interface Options {
    endpoint: string;
    element: { [key: string]: any };
    dictionaryContext?: string;
    columsToShow?: string[];
    dropdownElementAttributes?: string[];

    idsAttribute?: string;

    leftLabel?: string;
    rightLabel?: string;
    noDataMessage?: string;

    gridName?: string;
    draggableRows?: boolean;
}


interface CustomOptionProps {
    innerRef: any;
    innerProps: any;
    isSelected: boolean;
    data: any;
    selectOption: any;
}

export const RelationHandlerComponent = ({ options, setValue }: RelationHandlerComponentProps) => {
    const [items, setItems] = useState<any[]>([]); // Array di oggetti con i dati ricevuti dalla chiamata API
    const [data, setData] = useState<any[]>([]); // Array di oggetti con value e label per le opzioni del select
    const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
    const [gridApi, setGridApi] = useState<GridApi | null>(null);

    useEffect(() => {
        fetchData();
    }, [options.endpoint]);

    const fetchData = async () => {
        const response = await axiosAuthInstance.get(`${ApiConfig.ROOT_URL}${options.endpoint}`);
        setItems(response.data); // Memorizza gli elementi ricevuti dalla chiamata API

        const data = response.data.map((item: any) => ({ // Map degli elementi per creare un array di oggetti con value e label
            value: item.ID,
            // faccio in modo che dentro a label ci siano concatenate le stringhe dentro a dropdownElementAttributes
            label: options.dropdownElementAttributes ? options.dropdownElementAttributes.map((attribute) => item[attribute]).join(' ') : item.Name
        }));

        setData(data);
    };

    useEffect(() => {
        if (!data) 
            return;

        const key = Object.keys(options.element)[0];
        const el = options.element[key];

        if (!el) 
            return;
        
        const idsAttribute = options.idsAttribute ?? '';
        const ids: (string | number)[] = el[idsAttribute];

        if (!ids) 
            return;
        
        const selectedOptions = data.filter((item) => ids.includes(item.value));

        // const selectedOptions = [...ids]; 
        if (options.draggableRows) {
            // Crea una mappa per mantenere l'ordine originale degli ID
            const idOrderMap = new Map<string | number, number>(ids.map((id, index) => [id, index]));
            // Ordina selectedOptions in base all'ordine degli ID in el[options.idsAttribute]
            selectedOptions.sort((a, b) => (idOrderMap.get(a.value) ?? 0) - (idOrderMap.get(b.value) ?? 0));
        }

        setSelectedOptions(selectedOptions);
    }, [data]);

    useEffect(() => {
        if (!selectedOptions || selectedOptions.length === 0) {
            if (gridApi) {
                gridApi.updateGridOptions({ rowData: [] });
            }
            return;
        }
        createTable();
    }, [selectedOptions]);

    const createTable = () => {
        // Faccio in modo che se la tabella è già stata creata e gridApi è già stato inizializzato,
        // allora mi limito soltanto ad aggiornare i dati della tabella
        let gridColumnList: { headerName: string, field: string, rowDrag?: boolean}[] = [];

        // Controllo se almeno un elemento ha un'immagine
        
        // gridColumnList.push({
        //     headerName: 'Immagine',
        //     field: 'ID',
        // });


        for (const key in items[0]) {
            const field = key;
            if (!gridColumnList.find((column) => column.field === field)
                && (!options.columsToShow || options.columsToShow.includes(field))) {
                // Includo la colonna solo se il parametro columsToShow non è stato passato oppure se è stato passato e la colonna è inclusa

                let dictionaryField;
                Object.keys(TextDictionary.Admin.Tabelle).forEach((key) => {
                    if (key === options.dictionaryContext) {
                        dictionaryField = key;
                    }
                });

                gridColumnList.push({
                    headerName: dictionaryField ? TextDictionary.Admin.Tabelle[dictionaryField][field] : field,
                    field: field
                });
            }
        }

        console.log('OPTIONS', options);

        if (options.draggableRows) {
            // Faccio in modo che alla prima colonna venga aggiunta rowDrag
            const updatedGridColumnList = gridColumnList.map((column, index) => {
                if (index === 0) {
                    return {
                        ...column,
                        rowDrag: true,
                    };
                }
                return column;
            });

            gridColumnList = updatedGridColumnList;
        }

        console.log('GRID COLUMN LIST', gridColumnList);

        // Aggiungo la colonna delle azioni
        gridColumnList.push({
            headerName: 'Azioni',
            field: 'ID'
        });


        const columnDefs = gridColumnList.map((column) => {
            if (column.headerName === 'Azioni') {
                return {
                    headerName: '',
                    field: column.field,
                    minWidth: 150,
                    filter: false,
                    sortable: false,
                    cellRenderer: (params: any) => {
                        const fieldValue = params.value;
                        // faccio in modo che il value della riga sia l'ID dell'elemento, in modo da poterlo passare alla funzione di modifica o cancellazione
                        const handleRemove = (fieldValue: any) => {
                            // Trovo l'indice dell'elemento selezionato da eliminare
                            const elementIndex = selectedOptions.findIndex((item) => item.value === fieldValue);
                            selectedOptions.splice(elementIndex, 1);

                            // Aggiorno lo stato di formValues
                            if (setValue) {
                                // setFormValues((prevFormValues: any) => {
                                //     return { ...prevFormValues, [options.idsAttribute ?? '']: selectedOptions.map((item) => item.value) };
                                // });

                                setValue(options.idsAttribute ?? '', selectedOptions.map((item) => item.value));
                            }

                            //Aggiorno i dati della tabella
                            if (params.api) {
                                const rowData = selectedOptions.map((item: any) => items.find((i: any) => i.ID === item.value));
                                params.api.updateGridOptions({ rowData });
                            }
                        };

                        const container = document.createElement('div');
                        container.className = 'flex items-center justify-end gap-2 w-full mt-1';

                        const removeButton = document.createElement('button');
                        removeButton.className = 'bg-[#f5584d] text-white text-sm px-6 py-1 rounded-full hover:bg-transparent hover:text-errorRed border border-[#f5584d]';
                        removeButton.innerText = 'Elimina';
                        // come secondo parametro passo props.endpoint per poter costruire il link
                        removeButton.addEventListener('click', () => handleRemove(fieldValue));

                        container.appendChild(removeButton);

                        return container;
                    }
                };
            }

            return {
                headerName: column.headerName,
                field: column.field,
                sortable: true,
                filter: true,
                resizable: true,
                rowDrag: column.rowDrag
            }
        });

        const rowData = selectedOptions.map((item: any) => items.find((i: any) => i.ID === item.value));

        const gridOptions = {
            columnDefs,
            rowData,
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
            },
            // faccio in modo che non venga applicato nessun ordinamento di default
            sortingOrder: [],
            autoSizeStrategy: {
                type: "fitGridWidth",
            },
            overlayNoRowsTemplate: options.noDataMessage, // Messaggio da mostrare se non ci sono dati
            rowDragManaged: options.draggableRows,
            suppressMoveWhenRowDragging: options.draggableRows,
            onRowDragEnd: (event: any) => {
                // Popolo selectedElements con gli elementi visualizzati nella griglia pescandoli da event.api
                const displayedElements = event.api.getRenderedNodes().map((node: any) => {
                    return {
                        value: node.data.ID,
                        label: node.data.Title
                    };
                });

                setSelectedOptions(displayedElements);

                // Aggiorno lo stato di formValues
                if (setValue) {
                    // setFormValues((prevFormValues: any) => {
                    //     return { ...prevFormValues, [options.idsAttribute ?? '']: displayedElements.map((item: any) => item.value) };
                    // });

                    setValue(options.idsAttribute ?? '', displayedElements.map((item: any) => item.value));
                }
            },
        } as GridOptions;

        // Se voglio abilitare il drag delle rows, devo disabilitare la paginazione (da documentazione ag-grid)
        if (!options.draggableRows) {
            gridOptions.pagination = true;
            gridOptions.paginationPageSize = 10;
            gridOptions.paginationPageSizeSelector = [10];
        }

        const gridName = options.gridName ? options.gridName : 'resultsTable';

        const myGrid = document.getElementById(gridName) as HTMLElement;
        if (myGrid) {
            myGrid.innerHTML = '';
        }

        const grid = createGrid(myGrid, gridOptions);
        setGridApi(grid);
    }

    const handleChange = (selectedValues: any) => {
        setSelectedOptions(selectedValues);  // Memorizza gli elementi selezionati

        // Aggiorno lo stato di formValues
        if (setValue) {
            // setFormValues((prevFormValues: any) => {
            //     return { ...prevFormValues, [options.idsAttribute ?? '']: selectedValues.map((item: any) => item.value) };
            // });
            setValue(options.idsAttribute ?? '', selectedValues.map((item: any) => item.value));
        }

        // Aggiorno i dati della tabella
        if (gridApi) {
            const rowData = selectedValues.map((item: any) => items.find((i: any) => i.ID === item.value));
            gridApi.updateGridOptions({ rowData });
        }
    };

    const CustomOption = ({ innerRef, innerProps, isSelected, data, selectOption }: CustomOptionProps) => {
        // Aggiungi una funzione per gestire il click sulla checkbox
        const handleCheckboxChange = () => {
            selectOption(data); // Chiamata per selezionare/deselezionare l'opzione
        };

        return (
            <div ref={innerRef} {...innerProps} style={{ display: 'flex', alignItems: 'center', padding: '5px 15px', cursor: 'pointer' }}>
                <input
                    type="checkbox"
                    id={data.value}
                    checked={isSelected}
                    onChange={handleCheckboxChange}  // Gestisce la selezione
                    style={{ marginRight: 15 }}
                />
                <label
                    htmlFor={data.value}
                >{data.label}</label>
            </div>
        );
    };

    return (
        <div className="module-card flex flex-row w-full gap-4 items-start h-96 pb-8">
            <div className="w-1/2 pr-10 h-full">
                <span className="text-md font-semibold mb-2">{options.leftLabel}</span>
                {
                    selectedOptions.length === 0 && !gridApi ? (
                        <p className='mt-4'>{options.noDataMessage}</p>
                    ) : null
                }

                <div id={options.gridName ? options.gridName : 'resultsTable'} className="ag-theme-quartz h-full w-full mt-4 pb-8" />

            </div>
            <div className="w-1/2 h-full">
                <span className="text-md font-semibold mb-2">{options.rightLabel}</span>
                <Select
                    className='mt-4'
                    options={data}
                    value={selectedOptions.map((option: any) => data.find((o: any) => o.value === option.value))}  // Mostra le opzioni selezionate
                    controlShouldRenderValue={false}  // Nasconde il valore selezionato
                    isMulti
                    isSearchable
                    components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                        ClearIndicator: () => null,
                        Option: (props) => <CustomOption {...props} selectOption={props.selectOption} />
                    }}
                    onChange={handleChange}
                    closeMenuOnSelect={false}  // Mantiene il menu aperto dopo la selezione
                    hideSelectedOptions={false}  // Mantiene le opzioni selezionate visibili nella lista
                    placeholder="Cerca..."
                />
            </div>
        </div>
    );
}
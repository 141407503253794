import { useEffect } from "react";
import PublicLayout from "../layout/PublicLayout";
import { TextDictionary } from "../utils/TextDictionary";

const SubscriptionInfo = () => {
    return (
        <PublicLayout>
            <div>
                <h1 className="text-xl font-medium">{TextDictionary.InfoAbbonamento.Titolo}</h1>
            </div>
        </PublicLayout>
    );
};

export default SubscriptionInfo;
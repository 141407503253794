import PublicLayout from "../layout/PublicLayout";
import { TextDictionary } from "../utils/TextDictionary";
import HeaderTexture from "../assets/images/header-texture.png";
import { useLoaderData } from "react-router-dom";
import Slider from "react-slick";
import { CourseCard } from "../components/CourseCard";
import { Course } from "../models/course";

const Home = () => {
    const courses = useLoaderData() as Course[] || null;

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        centerMode: true,
        className: "center",
        adaptiveHeight: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    return (
        <PublicLayout>
            <div className="flex flex-col h-full w-full">
                <div className="w-full h-[400px] flex justify-center items-center bg-primary bg-no-repeat bg-cover mb-12" style={{ backgroundImage: `url(${HeaderTexture})` }}>
                    <h1 className="text-5xl font-bold text-center text-white bodoni">{TextDictionary.Home.Titolo}</h1>
                </div>

                <div className="flex flex-col justify-center items-center w-full gap-32 py-10 max-w-[1400px] mx-auto">
                    <div className="w-full h-auto">
                        <h2 className="text-5xl font-bold text-center text-primary bodoni mb-12">{TextDictionary.Home.CorsiEvidenza}</h2>
                        <div className="w-full">
                            {
                                !courses || courses.length === 0 ? (
                                    <div className="flex flex-col justify-center items-center w-full h-full">
                                        <h1 className="text-xl font-medium text-primary">{TextDictionary.Home.NessunCorso}</h1>
                                    </div>
                                ) : (
                                    <Slider {...sliderSettings}>
                                        {
                                            courses.map((course: any) => (
                                                <CourseCard key={course.ID} course={course} inverse />
                                            ))
                                        }
                                    </Slider>
                                )
                            }

                        </div>
                    </div>

                    <div className="w-full h-auto">
                        <h2 className="text-5xl font-bold text-center text-primary bodoni mb-12">{TextDictionary.Home.ProssimiLive}</h2>
                        <div className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-12 w-full">
                            {
                                !courses || courses.length === 0 ? (
                                    <div className="flex flex-col justify-center items-center w-full h-full">
                                        <h1 className="text-xl font-medium text-primary">{TextDictionary.Home.NessunCorso}</h1>
                                    </div>
                                ) : (
                                    courses.map((course: any) => (
                                        <CourseCard key={course.ID} course={course} />
                                    ))
                                )
                            }
                        </div>
                    </div>

                    <div className="w-full h-auto">
                        <h2 className="text-5xl font-bold text-center text-primary bodoni mb-12">{TextDictionary.Home.ScopriTuttiCorsi}</h2>
                        <div className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-12 w-full">
                            {
                                !courses || courses.length === 0 ? (
                                    <div className="flex flex-col justify-center items-center w-full h-full">
                                        <h1 className="text-xl font-medium text-primary">{TextDictionary.Home.NessunCorso}</h1>
                                    </div>
                                ) : (
                                    courses.map((course: any) => (
                                        <CourseCard key={course.ID} course={course} />
                                    ))
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </PublicLayout>
    );
};

export default Home;
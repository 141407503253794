import { ReactNode } from 'react';
import Header from '../components/Header';
import { AdminSidebar } from '../components/AdminSidebar';
import Footer from '../components/Footer';
import HeaderMobile from '../components/HeaderMobile';

interface AdminLayoutProps {
  children: ReactNode;
}

const AdminLayout = ({ children }: AdminLayoutProps) => {
  return (
    <div className="bg-white">
      <div className="flex h-screen overflow-hidden">
        <div className="relative flex flex-1 flex-col overflow-y-hidden overflow-x-hidden h-screen">
          <Header isUserSetted={false} isAdminArea={true}/>
          <HeaderMobile isUserSetted={true} isAdminArea={false}/>
          <div className="flex flex-row h-screen overflow-hidden">
            <AdminSidebar />
            <main className='grow'>
              <div className="px-12 py-8 h-full overflow-auto">
                {children}
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;

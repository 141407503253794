import axios from "axios";

const axiosLoginInstance = axios.create();

axiosLoginInstance.interceptors.request.use(config => {
  // const originService = OriginService.getInstance();
  try {
    // const originToken = originService.originToken;
    // config.headers["X-BBCRM-Origin"] = originToken;
    return config;
  } catch (err) {
    throw new Error("Error on login interceptor");
  }
});

export { axiosLoginInstance };
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Quiz } from "../models/quiz";
export interface QuestionElem {
    title?: string;
    type: 'intro' | 'question';
    duration?: number;
    description?: string;
    question?: string;
    content?: string;
    special?: 'writing' | 'listening';
    answers: AnswerElem[];
}

export interface AnswerElem {
    text: string;
    selected?: boolean;
    isCorrect?: boolean;
    points?: number;
}

export interface FinalDataQuestion {
    question: string;
    answer: string;
}
export interface FinalData {
    [key: string]: FinalDataQuestion[];
}

interface QuizComponentProps {
    quiz: Quiz;
}

export const QuizComponent = ({ quiz }: QuizComponentProps) => {
    const [data, setData] = useState<QuestionElem[]>(quiz.Data);
    const [currentModule, setCurrentModule] = useState<number>(-1);
    const [testStarted, setTestStarted] = useState(false);

    useEffect(() => {
        // Effettuo uno shuffle delle risposte di ogni domanda
        if (quiz && quiz.Data) {
            const updatedData = quiz.Data.map((item) => {
                if (item.type === 'question') {
                    const shuffledAnswers = item.answers.sort(() => Math.random() - 0.5);
                    console.log("shuffledAnswers", shuffledAnswers);
                    return {
                        ...item,
                        answers: shuffledAnswers
                    };
                }
                return item;
            });
            
    
            setData(updatedData);
        } 
    }, [quiz]);


    const renderTitle = (index: number) => { // Funzione che restituisce il titolo del modulo corrente
        const title = data.slice(0, index).reverse().find((elem) => elem.type === 'intro');
        if (title) {
            return <p className='text-[.875em] md:text-xl mb-2 md:mb-8 text-secondary uppercase font-medium'>{title.title}</p>;
        }
    };

    const moduleQuestionCounts = () => { // Funzione che restituisce un array con il numero di domande per ogni modulo
        const counts = []; // Array che conterrà il numero di domande per ogni modulo
        let currentCount = 0; // Conteggio corrente delle domande

        data.forEach((item) => {
            if (item.type === 'intro') { // Se l'elemento è di tipo intro, pusho il conteggio corrente nell'array counts e resetto il conteggio
                if (currentCount > 0) {
                    counts.push(currentCount);
                }
                currentCount = 0;
            } else if (item.type === 'question') { // Se l'elemento è di tipo question, incremento il conteggio corrente
                currentCount++;
            }
        });
        counts.push(currentCount); // Pusho l'ultimo conteggio

        return counts;
    };

    const counts = moduleQuestionCounts(); // Array che mappa il numero di domande per ogni modulo (intro)

    const renderProgress = (index: number) => { // Funzione che restituisce il conteggio delle domande per il modulo corrente
        let moduleIndex = -1; // -1 perchè il primo modulo è l'intro
        let questionIndex = 0; // 0 perchè la prima domanda è la prima del primo modulo

        for (let i = 0; i <= index; i++) {
            if (data[i].type === 'intro') { // Se l'elemento è di tipo intro, incremento l'indice del modulo e resetto l'indice della domanda
                moduleIndex++;
                questionIndex = 0;
            } else if (data[i].type === 'question') { // Se l'elemento è di tipo question, incremento l'indice della domanda
                questionIndex++;
            }
        }

        return <p className='text-[.875em] md:text-xl mb-2 md:mb-8 font-medium'>Question {questionIndex} / {counts[moduleIndex]} </p>; // Restituisco il conteggio delle domande per il modulo corrente
    }

    // Funzione che restituisce il numero di domande risposte
    const getAnsweredQuestionsCount = () => {
        return data.filter((item) => item.type === 'question').filter((item) => item.answers.find((answer) => answer.selected)).length;
    }

    // Funzione che restituisce il numero totale di domande
    const getTotalQuestions = () => {
        return data.filter((item) => item.type === 'question').length;
    }

    const handleScroll = (index: number) => { // Funzione che gestisce lo scroll verso la domanda successiva
        if (index + 1 === data.length) {
            const endElement = document.getElementById('end');
            endElement?.style.removeProperty('display');

            const element = document.getElementById('end');
            element?.scrollIntoView({ behavior: 'smooth' });
            return;
        }

        // Rimuovo il display none dalla domanda successiva
        const nextElement = document.getElementById('element-' + (index + 1));
        nextElement?.style.removeProperty('display');

        const element = document.getElementById('element-' + (index + 1));
        element?.scrollIntoView({ behavior: 'smooth' }); // Effettuo uno scroll verso la domanda successiva
    }

    const handleSubmit = () => { // Funzione che gestisce il submit del quiz
        // Calcolo il punteggio totale
        let totalPoints = 0;

        data.forEach((item) => { // Mi ciclo le risposte e calcolo il punteggio totale, considerando i punti per risposta corretta e sbagliata
            if (item.type === 'question') {
                item.answers.forEach((answer) => {
                    if (answer.selected) {
                        if (answer.isCorrect) {
                            totalPoints += quiz.CorrectAnswerPoints ?? 2;
                        } else {
                            totalPoints += quiz.WrongAnswerPoints ?? -1;
                        }
                    }
                });
            }
        });

        Swal.fire({
            title: 'Quiz completato!',
            text: `Hai totalizzato ${totalPoints} punti su un massimo di ${getTotalQuestions() * (quiz.CorrectAnswerPoints ?? 2)} punti.`,
            icon: 'success',
            confirmButtonText: 'Chiudi'
        });
    }

    return (
        <>
            {
                // Introduzione al quiz
                <div id="quiz-block" className="h-svh w-full flex flex-col md:flex-row justify-center items-center m-auto px-4 mt-4 border-2 border-black">
                    <div className='flex flex-col justify-center items-start'>
                        <h1 className='text-5xl md:text-8xl mb-2 md:mb-8 font-extrabold'>Carbognani</h1>
                        <h2 className='text-3xl md:text-4xl mb-2 md:mb-8 italic'>Quiz di conferma conoscenze</h2>

                        <button className='bg-primary rounded-full text-white px-10 py-2 mt-4 flex justify-center items-center'
                            onClick={() => {
                                const firstElement = document.getElementById('element-0');
                                firstElement?.style.removeProperty('display');

                                const element = document.getElementById('element-0');
                                element?.scrollIntoView({ behavior: 'smooth' });
                            }}>Procedi
                        </button>
                    </div>
                </div>
            }

            {
                data.map((item, index) => { // Mappo tutti gli elementi del quiz
                    return (
                        <div className={`h-svh w-full flex flex-col justify-center items-center m-auto px-4 md:px-0 border-2 border-black`} id={'element-' + index} style={{
                            display: 'none'
                        }}>
                            {/* Aggiungo un bottone per tornare alla domanda precedente */}
                            {index > 0 && <button className='previousQuestion bg-gray-200 rounded-full text-white px-2 py-2 flex justify-center items-center mb-6 md:mb-10' onClick={() => handleScroll(index - 2)}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" width="24px" x="0px" y="0px" viewBox="0 0 100 125" fill='gray'><g><g><path d="M74.2,60.1c0,2.8-2.2,5-5,5c-1.4,0-2.7-0.6-3.6-1.5L50,47.3L34.5,63.5c-1.9,2-5.1,2.1-7.1,0.1    c-2-1.9-2.1-5.1-0.2-7.1l19.2-20c1.9-2,5.1-2.1,7.1-0.2c0.1,0,0.1,0.1,0.2,0.2l19.2,20C73.7,57.6,74.2,58.8,74.2,60.1z" /></g></g></svg>
                            </button>}

                            <div className='flex flex-row gap-2 w-full justify-start md:justify-center items-baseline'>
                                {item.title && <h2 className='text-[1.35em] md:text-[2.25em] lg:text-[2.4em] mb-2 max-w-4xl font-bold'>{item.title}</h2>}
                            </div>

                            {item.description && <p className='text-md md:text-2xl mb-4 max-w-4xl'>{item.description.indexOf('<br>') > -1 ? item.description.split('<br>').map(
                                (item, index) => {
                                    return (
                                        <p key={index}>{item}</p>
                                    );
                                }
                            ) : item.description}</p>}

                            <div className='flex flex-row gap-2 w-full justify-start md:justify-center items-baseline'>
                                {item.type === 'question' && renderProgress(index)}
                                {item.type === 'question' && renderTitle(index)}
                            </div>

                            <div className='flex flex-row gap-2 w-full justify-start md:justify-center items-baseline'>
                                {item.question && (
                                    item.special !== 'writing' ?
                                        <h2 className='text-[1.35em] md:text-[2.25em] lg:text-[2.4em] mb-4 md:mb-8 max-w-4xl font-bold' dangerouslySetInnerHTML={{ __html: item.question }}></h2>
                                        : <h2 className='text-[1.35em] md:text-[1.5em] lg:text-[1.8em] mb-4 md:mb-8 max-w-4xl font-bold' dangerouslySetInnerHTML={{ __html: item.question }}></h2>
                                )}
                            </div>

                            <div className="flex flex-col gap-2 md:gap-4 px-4 md:px-0">
                                {
                                    item.answers.map((answer) => {
                                        return (
                                            <button
                                                // riscrivo className con un oggetto per gestire lo stile condizionale basato sul item.type
                                                className={item.type === 'intro' ? 'questionButton bg-primary rounded-full w-full text-white px-10 pt-[10px] pb-[5px] flex justify-center items-center' : 'questionButton rounded-[20px] flex-1 text-md md:text-xl w-full px-4 mt-4 pt-[16px] pb-[10px] flex justify-center items-center border-primary hover:bg-primary border-2 md:border-4 hover:shadow-lg'}
                                                style={
                                                    answer.selected ? {
                                                        backgroundColor: '#fdc54d',
                                                        borderColor: '#fdc54d'
                                                    } : {}
                                                }
                                                onClick={() => {
                                                    if (item.type === 'question') {
                                                        // Imposto selected a false per tutte le risposte della stessa domanda
                                                        const updatedDataA = data.map((elem, i) => {
                                                            if (i === index) {
                                                                elem.answers = elem.answers.map((a) => {
                                                                    return {
                                                                        ...a,
                                                                        selected: false
                                                                    };
                                                                });
                                                            }
                                                            return elem;
                                                        });

                                                        setData(updatedDataA);

                                                        // Imposto selected a true per la risposta selezionata della domanda corrente
                                                        const updatedAnswers = item.answers.map((a) => {
                                                            if (a.text === answer.text) {
                                                                return {
                                                                    ...a,
                                                                    selected: true
                                                                };
                                                            }
                                                            return a;
                                                        });

                                                        const updatedDataB = data.map((elem, i) => {
                                                            if (i === index) {
                                                                elem.answers = updatedAnswers;
                                                            }
                                                            return elem;
                                                        });

                                                        setData(updatedDataB);

                                                        // Gestione replace del placeholder con la risposta selezionata
                                                        const updatedDataC = data.map((elem, i) => {
                                                            if (i === index && elem.question) {
                                                                // Cerco all'interno di elem.question lo span con la classe 'answer-text' e lo sostituisco con la risposta selezionata
                                                                const currentSection = document.getElementById('element-' + index);
                                                                const answerText = currentSection?.querySelector('.answer-text') as HTMLElement;
                                                                if (answerText) {
                                                                    answerText.innerHTML = answer.text;
                                                                    answerText.style.color = '#ff6f39';
                                                                    answerText.style.textDecoration = 'underline';
                                                                    answerText.style.textDecorationThickness = '3px';
                                                                    answerText.style.textUnderlineOffset = '6px';
                                                                }
                                                            }
                                                            return elem;
                                                        });

                                                        setData(updatedDataC);

                                                        // Gestisco il salvataggio dello stato attuale del json e del tempo rimanente nel localStorage
                                                        localStorage.setItem('data-backup', JSON.stringify(updatedDataC));

                                                        handleScroll(index); // Gestisco lo scroll verso la domanda successiva

                                                    }
                                                    else if (item.type === 'intro') {
                                                        // Se l'elemento coincide con l'indice 0, invoco l'evento test_started
                                                        if (index === 0 && !testStarted) {
                                                            setTestStarted(true);
                                                        }

                                                        // Se l'elemento è di tipo intro, faccio scroll verso l'elemento successivo
                                                        handleScroll(index);

                                                        // Imposto l'indice del modulo corrente
                                                        setCurrentModule(index);
                                                    }
                                                }}
                                            >{answer.text}</button>
                                        );
                                    })
                                }
                            </div>
                            {
                                item.type === 'question' &&
                                <a className='skipButton underline text-md md:text-xl mt-8 mb-4 md:mt-12 cursor-pointer' onClick={() => {
                                    // Incremento questionCounter
                                    handleScroll(index);
                                }}>
                                    Skip
                                </a>
                            }
                        </div>
                    );
                })
            }
            <div className={`h-svh w-full flex flex-col justify-center items-center m-auto px-4 md:px-0 border-2 border-black`} id='end' style={{
                display: 'none'
            }}>
                <h2 className='text-4xl mb-2 font-bold'>Hai concluso il quiz!</h2>
                <h4 className='text-xl mb-2 font-normal pb-[50px]'>Hai risposto a {getAnsweredQuestionsCount()} domande su {getTotalQuestions()}
                    &nbsp;
                    <a className='underline cursor-pointer' onClick={() => {
                        const firstQuestionIndex = data.findIndex((item) => item.type === 'question');
                        handleScroll(firstQuestionIndex - 1);
                    }}>Clicca qui per ricontrollare le risposte</a>
                </h4>

                <button className='bg-primary rounded-full text-white px-10 py-2 mt-4 flex justify-center items-center' onClick={handleSubmit}>Concludi</button>
            </div>
        </>
    );
}

export default QuizComponent;

import { ReactNode } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

interface IDPLayoutProps {
  children: ReactNode;
}

const IDPLayout = ({ children }: IDPLayoutProps) => {
  return (
    <div className="bg-white">
      <div className="flex h-screen overflow-hidden">
        <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
          <main className='grow'>
            <div className="h-full mx-auto max-w-screen-md md:p-6 flex flex-col items-center justify-center">
              {children}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default IDPLayout;

import { TextDictionary } from "../../utils/TextDictionary";
import IDPLayout from "../../layout/IDPLayout";

const ResetPassword = () => {
    return (
        <IDPLayout>
            <div>
                <h1 className="text-xl font-medium">{TextDictionary.Autenticazione.ResetPassword.Titolo}</h1>
            </div>
        </IDPLayout>
    );
};

export default ResetPassword;
import { useCallback, useEffect, useRef, useState } from "react";
import AdminLayout from "../../../layout/AdminLayout";
import { unstable_usePrompt, useLoaderData, useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import { TextDictionary } from "../../../utils/TextDictionary";
import { FloatingBottomToolbar } from "../../../components/FloatingBottomToolbar";
import { ApiConfig } from "../../../config/Configuration";
import Editor, { BtnBold, BtnItalic, EditorProvider, Toolbar } from 'react-simple-wysiwyg';
import axios from "axios";
import { RelationHandlerComponent } from "../../../components/RelationHandlerComponent";
import { Course } from "../../../models/course";
import { saveFunction } from "../../../core/saveFunction";
import { Controller, useForm } from "react-hook-form";
import Swal from 'sweetalert2'
import { axiosAuthInstance } from "../../../auth/sso/auth.interceptor";
import EmptyAvatar from "../../../assets/images/empty-avatar.png";
import CustomDropzone from "../../../components/CustomDropzone";

const SingleCourse = () => {
    const navigate = useNavigate();

    const { id } = useParams();
    const course = useLoaderData() as any | null;

    const [selectedQuiz, setSelectedQuiz] = useState<any | null>(null);
    const [data, setData] = useState<any[]>([]); // Array di oggetti con value e label per le opzioni del select

    const [imageSrc, setImageSrc] = useState<string | undefined>(course?.ImageURL || undefined);

    const { register, handleSubmit, setValue, getValues, control, formState: { errors, isDirty, isSubmitting }, reset } = useForm<Course>({
        defaultValues: {
            PreviewVideoURL: course?.PreviewVideoURL || '',
            Title: course?.Title || '',
            Description: course?.Description || '',
            ShortDescription: course?.ShortDescription || '',
            Teacher: course?.Teacher || '',
            Date: course?.Date || '',
            Language: course?.Language || '',
            RegularPrice: course?.RegularPrice || 0,
            DiscountPrice: course?.DiscountPrice || null,
            URLLiveSession: course?.URLLiveSession || '',
            VideoIDs: course?.VideoIDs || [],
            ResourceIDs: course?.ResourceIDs || [],
            QuizID: course?.QuizID || null,
            ImageURL: course?.ImageURL || '',
            FileData: null,
            EmbeddedPreviewVideoURLHTML: course?.EmbeddedPreviewVideoURLHTML || ''
        }
    });
    const [isSaving, setIsSaving] = useState(false);
    const [toggleAlert, setToggleAlert] = useState(false);
    const onSubmit = (data: Course, saveAndClose?: boolean) => saveFunction(data, id, "courses", "Corsi", setIsSaving, navigate, saveAndClose); // Invoco la funzione di salvataggio 
    // passando i parametri necessari

    const options = {
        videos: {
            endpoint: "/videos",
            element: { course },
            dictionaryContext: "Video",
            columsToShow: ["Title", "Description"],
            dropdownElementAttributes: ["Title"],
            idsAttribute: "VideoIDs",
            leftLabel: TextDictionary.Admin.Corsi.VideoAssociati,
            rightLabel: TextDictionary.Admin.Corsi.AggiungiVideo,
            noDataMessage: TextDictionary.Admin.Corsi.NessunVideo,
            gridName: "videosGrid",
            draggableRows: true
        },
        resources: {
            endpoint: "/resources",
            element: { course },
            dictionaryContext: "Risorse",
            columsToShow: ["Description"],
            dropdownElementAttributes: ["Description"],
            idsAttribute: "ResourceIDs",
            leftLabel: TextDictionary.Admin.Corsi.RisorseAssociate,
            rightLabel: TextDictionary.Admin.Corsi.AggiungiRisorse,
            noDataMessage: TextDictionary.Admin.Corsi.NessunaRisorsa,
            gridName: "resourcesGrid",
        },
    }

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            // Stampo la combinazione [campo]: [messaggio di errore] per ogni errore di validazione
            Swal.fire({
                title: TextDictionary.Admin.Salvataggio.ErroreSalvataggio,
                text: Object.keys(errors).map(key => `${key}: ${(errors as any)[key].message}`).join('\n')
            });
        }
    }, [toggleAlert]);

    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            if (isDirty && !isSaving) {
                e.preventDefault();
                e.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isDirty, isSaving]);

    unstable_usePrompt({
        message: TextDictionary.Admin.Salvataggio.MessaggioModificheInSospeso,
        when: ({ currentLocation, nextLocation }) =>
            isDirty && !isSaving && currentLocation.pathname !== nextLocation.pathname
    });

    useEffect(() => {
        const fetchQuiz = async () => {
            const response = await axiosAuthInstance.get(`${ApiConfig.ROOT_URL}/quizzes`);
            const data = response.data;

            const options = data.map((quiz: any) => {
                return {
                    value: quiz.ID,
                    label: quiz.Title
                };
            });

            setData(options);

            if (!course) return;

            const selected = options.find((quiz: any) => quiz.value === course.QuizID);
            setSelectedQuiz(selected);
        };

        fetchQuiz();

        if (course) {
            const formattedDate = new Date(course.Date);

            // // riscrivo aggiungendo lo 0 davanti ai numeri minori di 10 sia per il mese che per il giorno e per le ore
            const formattedDateString = `${formattedDate.getFullYear()}-${(formattedDate.getMonth() + 1).toString().padStart(2, '0')}-${formattedDate.getDate().toString().padStart(2, '0')}T${formattedDate.getHours().toString().padStart(2, '0')}:${formattedDate.getMinutes().toString().padStart(2, '0')}`;

            reset({
                PreviewVideoURL: course.PreviewVideoURL,
                Title: course.Title,
                Description: course.Description,
                ShortDescription: course.ShortDescription,
                Teacher: course.Teacher,
                Date: formattedDateString,
                Language: course.Language,
                RegularPrice: course.RegularPrice || 0,
                DiscountPrice: course.DiscountPrice || null,
                URLLiveSession: course.URLLiveSession,
                VideoIDs: course.VideoIDs,
                ResourceIDs: course.ResourceIDs,
                QuizID: selectedQuiz?.value || course.QuizID,
                ImageURL: course.ImageURL || '',
                FileData: null,
                EmbeddedPreviewVideoURLHTML: course.EmbeddedPreviewVideoURLHTML || ''
            });

            setImageSrc(course.ImageURL || undefined);
        }
    }, [course]);

    const handleChange = (selected: any) => {
        // Gestisco la casistica in cui l'utente rimuove la selezione
        setSelectedQuiz(selected);
        setValue("QuizID", +selected?.value || null);
    };

    const handleDiscard = () => {
        navigate('/admin/courses');
    }

    const handleRemoveImage = () => {
        setImageSrc(undefined);
        setValue("FileData", null);
        setValue("ImageURL", null);
    }

    // const handleImage = () => {
    //     const input = document.createElement('input');
    //     input.type = 'file';
    //     input.accept = 'image/*';

    //     // controllo che il peso non superi 10 mb
    //     input.click();

    //     input.addEventListener('change', async (e) => {
    //         const file = (e.target as HTMLInputElement).files?.[0];
    //         if (file) {
    //             const newImageSrc = URL.createObjectURL(file);
    //             setImageSrc(newImageSrc);
    //             setValue("FileData", file);
    //         }
    //     });
    // }

    // riscrivo la funzione handleImage facendo si che se il file supera 10 mb, venga mostrato un alert senza valorizzare imageSrc e FileData
    // const handleImage = () => {
    //     const input = document.createElement('input');
    //     input.type = 'file';
    //     input.accept = 'image/*';

    //     // controllo che il peso non superi 10 mb
    //     input.click();

    //     input.addEventListener('change', async (e) => {
    //         const file = (e.target as HTMLInputElement).files?.[0];
    //         if (file && file.size <= 10485760) {
    //             const newImageSrc = URL.createObjectURL(file);
    //             setImageSrc(newImageSrc);
    //             setValue("FileData", file);
    //         } else {
    //             Swal.fire({
    //                 title: TextDictionary.Admin.Corsi.ImmagineSuperaDimensioni
    //             });
    //         }
    //     });
    // }

    const handleSave = (saveAndClose?: boolean) => {
        handleSubmit(() => onSubmit(getValues(), saveAndClose))()
            .then((e) => {
                setToggleAlert(!toggleAlert);
            },
                (error) => {
                    // alert("Errore durante il salvataggio: \n " + error);

                    const errorMessages = [];
                    for (const key in error.response.data.errors) {
                        errorMessages.push(`${key}: ${error.response.data.errors[key]}`);
                    }

                    Swal.fire({
                        title: TextDictionary.Admin.Salvataggio.ErroreSalvataggio,
                        text: errorMessages.join('\n')
                    });
                }
            );
    }

    // const onDrop = (acceptedFiles: File[]) => {
    //     const file = acceptedFiles[0];
    //     const newImageSrc = URL.createObjectURL(file);
    //     setImageSrc(newImageSrc);
    //     setValue("FileData", file);
    // }

    const onDrop = useCallback((acceptedFiles: any) => {
        acceptedFiles.map((file: any) => {
            const reader = new FileReader();
            reader.onload = function (e) {
                const data = e.target?.result;
                setImageSrc(data as string);
                setValue("FileData", file);
            };

            reader.readAsDataURL(file);
            return file;
        });
    }, []);

    return (
        <AdminLayout>
            <div className="flex flex-row w-full gap-4">
                <div className="flex flex-col gap-4 flex-1 w-full">
                    <h1 className="text-2xl font-semibold mb-8">{id ? TextDictionary.Admin.Corsi.ModificaCorso : TextDictionary.Admin.Corsi.NuovoCorso}</h1>

                    <div className="flex flex-row w-full gap-8">
                        <div className="flex flex-col gap-4 w-full flex-1">
                            <div className="module-card flex flex-col w-full">
                                {/* Nome */}
                                <div className="flex flex-col mb-4 w-full">
                                    <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Corsi.CampoTitolo}*</label>
                                    <input type="text" id="name" className="border border-black border-opacity-10 w-full px-2 py-1"
                                        {...register("Title", { required: TextDictionary.Admin.Form.CampoObbligatorio })}
                                        autoFocus
                                    />
                                    {errors.Title && <p role="alert" className="text-errorRed text-sm">{errors.Title.message}</p>}
                                </div>

                                {/* Descrizione breve */}
                                <div className="flex flex-col mb-4 w-full">
                                    <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Corsi.CampoDescrizioneBreve}</label>
                                    <Controller
                                        name="ShortDescription"
                                        control={control}
                                        render={({ field: { onChange, value, ref } }) => (
                                            <EditorProvider>
                                                <Editor
                                                    onChange={(e) => onChange(e.target.value)}
                                                    value={value}
                                                    className="border border-black border-opacity-10 w-full resize-none px-2"
                                                    containerProps={{ style: { height: '200px', width: '100%' } }}
                                                    name="ShortDescription"
                                                >
                                                    <Toolbar>
                                                        <BtnBold />
                                                        <BtnItalic />
                                                    </Toolbar>
                                                </Editor>
                                            </EditorProvider>
                                        )}
                                    />
                                </div>

                                {/* Descrizione */}
                                <div className="flex flex-col mb-4 w-full">
                                    <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Corsi.CampoDescrizione}</label>
                                    <Controller
                                        name="Description"
                                        control={control}
                                        render={({ field: { onChange, value, ref } }) => (
                                            <EditorProvider>
                                                <Editor
                                                    onChange={(e) => onChange(e.target.value)}
                                                    value={value}
                                                    className="border border-black border-opacity-10 w-full resize-none px-2"
                                                    containerProps={{ style: { height: '200px', width: '100%' } }}
                                                    name="Description"
                                                >
                                                    <Toolbar>
                                                        <BtnBold />
                                                        <BtnItalic />
                                                    </Toolbar>
                                                </Editor>
                                            </EditorProvider>
                                        )}
                                    />
                                </div>
                            </div>

                            {/* Data */}
                            <div className="module-card flex flex-col mb-4 w-full">
                                <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Corsi.CampoData}*</label>
                                <input aria-label="Data e ora" type="datetime-local"
                                    {...register("Date", { required: TextDictionary.Admin.Form.CampoObbligatorio })}
                                    className="border border-black border-opacity-10 w-full px-2 py-1" onClick={(e) => e.currentTarget.showPicker()} />
                                {errors.Date && <p role="alert" className="text-errorRed text-sm">{errors.Date.message}</p>}
                            </div>
                        </div>

                        <div className="flex flex-col gap-4 w-full flex-1">
                            {/* Immagine */}
                            <div className="module-card flex flex-col w-full">
                                <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Corsi.CampoImmagine}</label>
                                <div className="relative">
                                    {/* se non è presente FileName, mostro il dropzone per caricare il file */}
                                    {imageSrc === undefined && (
                                        <CustomDropzone
                                            onDrop={onDrop}
                                            accept={{
                                                accept: 'image/*',
                                            }}
                                            maxFiles={1}
                                        />
                                    ) || (
                                            <div className="flex flex-col gap-4 mb-4 w-full border">
                                                <div className="flex flex-row items-center justify-center gap-4">
                                                    <img src={imageSrc} alt="Immagine corso" className="w-full h-full object-contain" />
                                                    <button
                                                        onClick={handleRemoveImage}
                                                        className="absolute top-0 right-0 bg-errorRed text-white rounded-full h-8 w-8 flex justify-center items-center"
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            </div>


                            {/* Quiz associato */}
                            <div className="module-card flex flex-col w-full">
                                <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Corsi.QuizAssociato}</label>
                                <Controller
                                    name="QuizID"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            className='mb-4'
                                            options={data}
                                            value={selectedQuiz}
                                            isSearchable
                                            isClearable // Permette di svuotare il campo di ricerca
                                            onChange={handleChange}
                                            closeMenuOnSelect={true}  // Mantiene il menu aperto dopo la selezione
                                            hideSelectedOptions={false}  // Mantiene le opzioni selezionate visibili nella lista
                                            placeholder="Cerca quiz..."
                                        />
                                    )}
                                />
                            </div>

                            <div className="module-card flex flex-col w-full">
                                {/* URL Video Live */}
                                <div className="flex flex-col mb-4 w-full">
                                    <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Corsi.CampoURLLive}</label>
                                    <input type="text" id="name" className="border border-black border-opacity-10 w-full px-2 py-1"
                                        {...register("URLLiveSession")}
                                    />
                                </div>

                                {/* URL Video Anteprima */}
                                <div className="flex flex-col mb-4 w-full">
                                    <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Corsi.CampoPreview}</label>
                                    <input type="text" id="name" className="border border-black border-opacity-10 w-full px-2 py-1"
                                        {...register("PreviewVideoURL")}
                                    />
                                </div>

                                {
                                    course?.EmbeddedPreviewVideoURLHTML && (
                                        // Creo un div che abbia come innerHTML il valore dell'attributo EmbeddedHTML
                                        <div
                                            dangerouslySetInnerHTML={{ __html: course?.EmbeddedPreviewVideoURLHTML || '' }}
                                            className="course-iframe-container mt-4 w-full"
                                        />
                                    )
                                }
                            </div>

                            {/* Docente */}
                            <div className="module-card flex flex-col mb-4 w-full">
                                <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Corsi.CampoDocente}</label>
                                <input type="text" id="name" className="border border-black border-opacity-10 w-full px-2 py-1"
                                    {...register("Teacher")}
                                />
                            </div>

                            {/* Lingua */}
                            <div className="module-card flex flex-col mb-4 w-full">
                                <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Corsi.CampoLingua}</label>
                                <input type="text" id="name" className="border border-black border-opacity-10 w-full px-2 py-1"
                                    {...register("Language")}
                                />
                            </div>

                            {/* Prezzo */}
                            <div className="module-card flex flex-row mb-4 gap-8 w-full">
                                <div className="flex flex-col w-full">
                                    <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Corsi.CampoPrezzo}*</label>
                                    <div className="flex flex-row gap-2 items-center">
                                        <input type="number" id="name" className="border border-black border-opacity-10 w-full px-2 py-1"
                                            {...register("RegularPrice", { required: TextDictionary.Admin.Form.CampoObbligatorio, valueAsNumber: true })}
                                        />
                                        <span className="font-semibold text-md">€</span>
                                    </div>
                                    {errors.RegularPrice && <p role="alert" className="text-errorRed text-sm">{errors.RegularPrice.message}</p>}
                                </div>
                                <div className="flex flex-col w-full">
                                    <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Corsi.CampoPrezzoScontato}</label>
                                    <div className="flex flex-row gap-2 items-center">
                                        <input type="number" id="name" className="border border-black border-opacity-10 w-full px-2 py-1"
                                            {...register("DiscountPrice", { valueAsNumber: true })}
                                        />
                                        <span className="font-semibold text-md">€</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Associazione con i video */}
                    <RelationHandlerComponent
                        options={options.videos}
                        setValue={setValue}
                    />

                    {/* Associazione con le risorse */}
                    <RelationHandlerComponent
                        options={options.resources}
                        setValue={setValue}
                    />
                </div>

                {/* Card di salvataggio */}
                <FloatingBottomToolbar onSave={() => {
                    handleSave(false);
                }}
                    onSaveAndClose={() => {
                        handleSave(true);
                    }} onDiscard={() => {
                        handleDiscard();
                    }}
                    isSubmitting={isSubmitting}
                />
            </div>


        </AdminLayout>
    );
};

export default SingleCourse;
import { useEffect, useState } from "react";
import { useLoaderData, useParams } from "react-router-dom";
import { Course } from "../models/course";
import { Video } from "../models/video";
import { axiosAuthInstance } from "../auth/sso/auth.interceptor";
import { ApiConfig } from "../config/Configuration";
import PublicLayout from "../layout/PublicLayout";
import { PrimaryButton } from "../components/PrimaryButton";
import { QuizComponent } from "../components/QuizComponent";
import { Quiz } from "../models/quiz";
import { TextDictionary } from "../utils/TextDictionary";


interface QuestionElem { // Da spostare in un file a parte
    title?: string;
    type: 'intro' | 'question';
    duration?: number;
    description?: string;
    question?: string;
    content?: string;
    special?: 'writing' | 'listening';
    answers: AnswerElem[];
}

interface AnswerElem { // Da spostare in un file a parte
    text: string;
    selected?: boolean;
    isCorrect?: boolean;
    points?: number;
}


export const PublicSingleVideo = () => {
    const { id } = useParams();
    const video = useLoaderData() as Video;

    const [quiz, setQuiz] = useState<Quiz | null>(null);
    const [quizStarted, setQuizStarted] = useState(false);

    useEffect(() => {
        const fetchQuiz = async () => {
            const response = await axiosAuthInstance.get(`${ApiConfig.ROOT_URL}/quizzes/${video.QuizID}`);
            setQuiz(response.data); // Imposto quiz con l'oggetto Quiz, che contiene le informazioni del quiz.
            // attraverso il componente QuizComponent.
        }

        if (video) {
            fetchQuiz();
        }
    }, [video]);

    return (
        <PublicLayout>
            <div className="container h-screen w-full mx-auto flex flex-col justify-center items-center">
                <h1 className="text-4xl font-semibold mb-4">{video?.Title}</h1>

                <div
                    dangerouslySetInnerHTML={{ __html: video?.EmbeddedHTML || '' }}
                    className="w-full flex items-center justify-center mb-8"
                />

                {
                    quiz && ( // Se risulta presente un quiz associato al video, allora mostro il bottone per iniziare il quiz (successivamente verrà condizionato anche status del video)
                        <PrimaryButton
                            onClick={() => {
                                setQuizStarted(true);

                                setTimeout(() => {
                                    const quizBlock = document.getElementById('quiz-block');
                                    quizBlock?.scrollIntoView({ behavior: 'smooth' });
                                }, 200);
                            }} text={TextDictionary.PaginaVideo.BottoneIniziaQuiz} />
                    )
                }
            </div>
            {quiz && quizStarted && (<QuizComponent quiz={quiz} />)}
        </PublicLayout>
    )
}
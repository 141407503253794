import { LoginRequest, LoginResponse } from "../interfaces/login.interface";
import { axiosLoginInstance } from "./login.interceptor";
import { IDPPermission, UserService } from "../../services/user.service";

import { ApiConfig } from "../../config/Configuration";
import { RegisterRequest } from "./auth.types";
import { jwtDecode } from "jwt-decode";

export class AuthService {

    private constructor() {
        // Costruttore privato
    }

    private static instance: AuthService;

    public static getInstance(): AuthService {
        if (!AuthService.instance) {
            AuthService.instance = new AuthService();
        }
        return AuthService.instance;
    }

    private loginUrl = `${ApiConfig.ROOT_URL}${ApiConfig.LOGIN_URL}`;
    private signupUrl = `${ApiConfig.ROOT_URL}${ApiConfig.REGISTER_URL}`;


    // Creo un dizionario per i gruppi utente, sapendo che 11 è utente registrato, 12 è admin e 13 è super admin
    private userGroups: { [key: number]: string } = {
        11: 'User',
        12: 'Admin',
        13: 'Super Admin'
    };

    public isUserAdmin(groupId: number): boolean {
        // Mi baso su userGroups per verificare se l'utente è admin, cioè se il suo gruppo è 12 o 13
        const group = this.userGroups[groupId];
        return group === 'Admin' || group === 'Super Admin'; // Se il gruppo è Admin o Super Admin, l'utente è admin
    }


    get isAdmin(): boolean {
        if (!this.accessToken) return false;
        
        const decodedToken: any = jwtDecode(AuthService.getInstance().accessToken)

        return this.isUserAdmin(decodedToken.GroupID);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? "";
    }

    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    public getEnum(value: string): IDPPermission | undefined {
        // Verificare se il valore è presente nell'enum
        if (Object.values(IDPPermission).includes(value as IDPPermission)) {
            return value as IDPPermission;
        } else {
            return undefined;
        }
    }

    public async signIn(credentials: LoginRequest) {
        const response = await axiosLoginInstance.post(this.loginUrl, credentials);
        this.accessToken = response.data.Token;
        UserService.getInstance().user = response.data.User;

        return true;
    }

    public async signUp(data: Object) { // Da tipizzare
        const response = await axiosLoginInstance.post(this.signupUrl, data);
        this.accessToken = response.data.Token;
        UserService.getInstance().user = response.data.User;

        return true;
    }

    public signOut() {
        try {
            // Rimuovo l'utente dal servizio UserService
            UserService.getInstance().user = null;
            UserService.getInstance().permissions = [];

            // Rimuovo il token dal servizio AuthService
            this.accessToken = "";
            // Rimuovo il token dal local storage
            localStorage.removeItem('accessToken'); // Cancello il token dal local storage
            return true;
        } catch (error) {
            return false;
        }
    }

}

import { useEffect, useState } from "react";
import AdminLayout from "../../../layout/AdminLayout";
import { Link, unstable_usePrompt, useLoaderData, useNavigate, useParams } from "react-router-dom";
import EmptyAvatar from "../../../assets/images/empty-avatar.png";
import { TextDictionary } from "../../../utils/TextDictionary";
import { RelationHandlerComponent } from "../../../components/RelationHandlerComponent";
import { EmployeesData, User } from "../../../models/user";
import axios from "axios";
import { ApiConfig } from "../../../config/Configuration";
import { FloatingBottomToolbar } from "../../../components/FloatingBottomToolbar";
import { set, useForm } from "react-hook-form";
import { saveFunction } from "../../../core/saveFunction";
import Swal from "sweetalert2";
import { Subscription } from "../../../models/subscription";

const SingleUser = () => {
    const navigate = useNavigate();
    // Pesco dall'url l'id dell'utente
    const { id } = useParams();
    const user = useLoaderData() as User | null;

    const [imageSrc, setImageSrc] = useState(user?.URLImage || EmptyAvatar);
    const { register, setValue, getValues, control, handleSubmit, formState: { errors, isDirty, isSubmitting }, reset } = useForm<User>({
        defaultValues: {
            Name: user?.Name || '',
            Surname: user?.Surname || '',
            Company: user?.Company || '',
            Email: user?.Email || '',
            Phone: user?.Phone || '',
            Province: user?.Province || '',
            CouponIDs: user?.CouponIDs || [],
            URLImage: user?.URLImage || '',
        }
    });
    const [isSaving, setIsSaving] = useState(false);
    const [toggleAlert, setToggleAlert] = useState(false);

    const onSubmit = (data: User, saveAndClose?: boolean) => saveFunction(data, id, "users", "Utenti", setIsSaving, navigate, saveAndClose);


    // Variabili per la gestione degli abbonamenti (sia come proprietario che come employee)
    const [ownerEmail, setOwnerEmail] = useState<string>('');
    const [subscriptionName, setSubscriptionName] = useState<string | null>(null);
    const [employeesNumber, setEmployeesNumber] = useState<number | null>(null);
    const [employees, setEmployees] = useState<EmployeesData[]>([]);
    const [expirationDate, setExpirationDate] = useState<string | null>(null);



    const options = {
        coupons: {
            endpoint: "/coupons",
            element: { user },
            dictionaryContext: "Coupon",
            columsToShow: ["Title", "Description"],
            dropdownElementAttributes: ["Title"],
            idsAttribute: "CouponIDs",
            leftLabel: TextDictionary.Admin.Utenti.CouponAssociati,
            rightLabel: TextDictionary.Admin.Utenti.AggiungiCoupon,
            noDataMessage: TextDictionary.Admin.Utenti.NessunCoupon,
            gridName: "couponsGrid",
        },
    }

    useEffect(() => {
        if (user) {
            reset({
                Name: user.Name,
                Surname: user.Surname,
                Company: user.Company,
                Email: user.Email,
                Phone: user.Phone,
                Province: user.Province,
                CouponIDs: user.CouponIDs,
            });

            if (user.UserSubscriptionData) { // Se questo è valorizzato, vuol dire che l'utente è proprietario di un abbonamento
                setSubscriptionName(user.UserSubscriptionData[0].SubscriptionName); // Setto il nome dell'abbonamento
                setEmployeesNumber(user.UserSubscriptionData[0].EmployeesNumber); // Setto il numero di dipendenti
                setEmployees(user.UserSubscriptionData[0].Employees?.map((employee) => { // Setto i dipendenti
                    return {
                        Name: employee.Name,
                        Surname: employee.Surname,
                        Email: employee.Email
                    };
                }) || []);
                setExpirationDate(user.UserSubscriptionData[0].ExpireDate); // Setto la data di scadenza
            }

            if (user.UserSubscriptionEmployeeData) {
                setOwnerEmail(user.UserSubscriptionEmployeeData[0].UserSubscriptionOwnerEmail); // Setto l'email del proprietario dell'abbonamento
                setSubscriptionName(user.UserSubscriptionEmployeeData[0].SubscriptionName); // Setto il nome dell'abbonamento
                setExpirationDate(user.UserSubscriptionEmployeeData[0].ExpireDate); // Setto la data di scadenza
            }
        }
    }, [user]);

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            // Stampo la combinazione [campo]: [messaggio di errore] per ogni errore di validazione
            Swal.fire({
                title: TextDictionary.Admin.Salvataggio.ErroreSalvataggio,
                text: Object.keys(errors).map(key => `${key}: ${(errors as any)[key].message}`).join('\n')
            });
        }
    }, [toggleAlert]);

    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            if (isDirty && !isSaving) {
                e.preventDefault();
                e.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isDirty, isSaving]);

    unstable_usePrompt({
        message: TextDictionary.Admin.Salvataggio.MessaggioModificheInSospeso,
        when: ({ currentLocation, nextLocation }) =>
            isDirty && !isSaving && currentLocation.pathname !== nextLocation.pathname
    });

    const handleDiscard = () => {
        // Mostro un avviso se il form è stato modificato
        navigate('/admin/users');
    }

    const handleRemoveImage = () => {
        setValue('URLImage', EmptyAvatar);
        setImageSrc(EmptyAvatar);
    }

    const handleSave = (saveAndClose?: boolean) => {
        handleSubmit(() => onSubmit(getValues(), saveAndClose))()
            .then((e) => {
                setToggleAlert(!toggleAlert);
            },
                (error) => {
                    const errorMessages = [];
                    for (const key in error.response.data.errors) {
                        errorMessages.push(`${key}: ${error.response.data.errors[key]}`);
                    }
                    Swal.fire({
                        title: TextDictionary.Admin.Salvataggio.ErroreSalvataggio,
                        text: errorMessages.join('\n')
                    });
                }
            );
    }

    return (
        <AdminLayout>
            <div className="px-4 py-2 relative overflow-hidden">
                <h1 className="text-2xl font-semibold mb-8">{id ? TextDictionary.Admin.Utenti.ModificaUtente : TextDictionary.Admin.Utenti.NuovoUtente}</h1>

                {/* Dati anagrafici */}
                <div className="grid grid-cols-3 border border-black border-opacity-10 mb-8">
                    <div className="col-span-1 border-r border-black border-opacity-10 flex justify-center">
                        <div className="relative">
                            <img
                                src={imageSrc}
                                alt="Empty avatar illustration"
                                className="w-[150px] h-[150px] mx-auto cursor-pointer rounded-full border border-black border-opacity-20"
                                onClick={() => {
                                    const input = document.createElement('input');
                                    input.type = 'file';
                                    input.accept = 'image/*';
                                    input.click();

                                    input.addEventListener('change', async (e) => {
                                        const file = (e.target as HTMLInputElement).files?.[0];
                                        if (file) {
                                            const newImageSrc = URL.createObjectURL(file);
                                            setValue('URLImage', newImageSrc);
                                            setImageSrc(newImageSrc);
                                        }
                                    });
                                }}
                            />
                            {imageSrc !== EmptyAvatar && (
                                <button
                                    onClick={handleRemoveImage}
                                    className="absolute top-0 right-0 bg-errorRed text-white rounded-full h-8 w-8 flex justify-center items-center"
                                >
                                    X
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="col-span-2">
                        <div className="flex flex-col border-b border-black border-opacity-10 pb-4">
                            <div className="grid grid-cols-2 gap-4 p-4">
                                <div className="flex flex-row gap-8 items-center">
                                    <label htmlFor="name" className="font-medium text-sm">{TextDictionary.Admin.Utenti.CampoNome}</label>
                                    <input type="text" id="name" className="border border-black border-opacity-10 px-4 py-1"
                                        {...register('Name')}
                                        autoFocus />
                                </div>
                                <div className="flex flex-row gap-8 items-center">
                                    <label htmlFor="surname" className="font-medium text-sm">{TextDictionary.Admin.Utenti.CampoCognome}</label>
                                    <input type="text" id="surname" className="border border-black border-opacity-10 px-4 py-1"
                                        {...register('Surname')}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-row gap-8 p-4 items-center">
                                <label htmlFor="company" className="font-medium text-sm">{TextDictionary.Admin.Utenti.CampoAzienda}</label>
                                <input id="company" className="border border-black border-opacity-10 px-4 py-1"
                                    {...register('Company')}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col gap-4 pt-4">
                            <div className="flex flex-row gap-8 p-4 items-center">
                                <label htmlFor="email" className="font-medium text-sm">{TextDictionary.Admin.Utenti.CampoEmail}</label>
                                <input type="text" id="email" className="border border-black border-opacity-10 px-4 py-1"
                                    {...register('Email')}
                                    disabled={id ? true : false}
                                    style={{ backgroundColor: id ? '#f9f9f9' : 'white' }}
                                />
                            </div>
                            <div className="flex flex-row gap-8 p-4 items-center">
                                <label htmlFor="phone" className="font-medium text-sm">{TextDictionary.Admin.Utenti.CampoTelefono}</label>
                                <input type="text" id="phone" className="border border-black border-opacity-10 px-4 py-1"
                                    {...register('Phone')}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="module-card flex flex-col mb-8">
                    {/* Abbonamenti e informazioni */}

                    { /* Se l'utente non è proprietario, mostro l'email dell'utente proprietario che detiene l'abbonamento, il nome dell'abbonamento e la data di scadenza (non modificabile) */}
                    {
                        user?.UserSubscriptionEmployeeData && (
                            <div className="flex flex-col gap-4 p-4">
                                <div className="flex flex-row gap-8 items-center">
                                    <label htmlFor="ownerMail" className="font-medium text-sm">{TextDictionary.Admin.Utenti.CampoEmailProprietario}</label>
                                    <input type="text" id="ownerMail" className="border border-black border-opacity-10 bg-[#f9f9f9] px-4 py-1" value={ownerEmail} disabled />
                                </div>

                                <div className="flex flex-row gap-8 items-center">
                                    <label htmlFor="subscription" className="font-medium text-sm">{TextDictionary.Admin.Utenti.CampoNomeAbbonamento}</label>
                                    <input type="text" id="subscription" className="border border-black border-opacity-10 bg-[#f9f9f9] px-4 py-1" value={subscriptionName ?? ''} disabled />

                                    <label htmlFor="expirationDate" className="font-medium text-sm">{TextDictionary.Admin.Utenti.CampoDataScadenza}</label>
                                    <input type="date" id="expirationDate" className="border border-black border-opacity-10 bg-[#f9f9f9] px-4 py-1" value={expirationDate ?? ''} disabled />
                                </div>
                            </div>
                        )
                    }

                    { /* Se l'utente è proprietario, mostro in una row il nome dell'abbonamento dentro ad una input disabilitata e la scadenza con un date picker per la data di scadenza */}
                    {
                        user?.UserSubscriptionData && (
                            <div className="flex flex-col gap-4 p-4">
                                <div className="flex flex-row items-center gap-8 ">
                                    <label htmlFor="subscription" className="font-medium text-sm">{TextDictionary.Admin.Utenti.CampoNomeAbbonamento}</label>
                                    <input type="text" id="subscription" className="border border-black border-opacity-10 bg-[#f9f9f9] px-4 py-1" value={subscriptionName ?? ''} disabled />

                                    <label htmlFor="expirationDate" className="font-medium text-sm">{TextDictionary.Admin.Utenti.CampoDataScadenza}</label>
                                    <input type="date" id="expirationDate" className="border border-black border-opacity-10 px-4 py-1" value={expirationDate ?? ''} />
                                </div>

                                <div className="flex flex-row gap-8">
                                    <label htmlFor="employeesNumber" className="font-medium text-sm pt-1">{TextDictionary.Admin.Utenti.CampoNumeroDipendenti}</label>
                                    <input type="number" id="employeesNumber" className="border border-black border-opacity-10 w-16 h-fit px-4 py-1" value={employeesNumber ?? 0} onChange={(e) =>
                                        setEmployeesNumber(parseInt(e.target.value))}
                                    />

                                    <div className="flex flex-col gap-4">
                                        {/* Riscrivo facendo in modo che vengano visualizzate tante input quanto l'employeesNumber, e che vengano valorizzate le input degli employee presenti dentro employees */}
                                        {
                                            new Array(employeesNumber).fill(0).map((_, index) => (
                                                <div key={index} className="flex flex-row gap-8">
                                                    <input type="text" className="border border-black border-opacity-10 px-4 py-1" value={employees[index]?.Name} onChange={(e) => {
                                                        const newEmployees = [...employees];
                                                        newEmployees[index].Name = e.target.value;
                                                        setEmployees(newEmployees);
                                                    }} placeholder="Nome" />
                                                    <input type="text" className="border border-black border-opacity-10 px-4 py-1" value={employees[index]?.Surname} onChange={(e) => {
                                                        const newEmployees = [...employees];
                                                        newEmployees[index].Surname = e.target.value;
                                                        setEmployees(newEmployees);
                                                    }} placeholder="Cognome" />
                                                    <input type="text" className="border border-black border-opacity-10 px-4 py-1" value={employees[index]?.Email} onChange={(e) => {
                                                        const newEmployees = [...employees];
                                                        newEmployees[index].Email = e.target.value;
                                                        setEmployees(newEmployees);
                                                    }} placeholder="Email" />
                                                    {/* aggiungo un tasto per rimuovere l'elemento, presente solo se la row è valorizzata */}
                                                    {
                                                        employees[index] && (
                                                            <button className="bg-errorRed text-white rounded-md aspect-square" onClick={() => {
                                                                const newEmployees = [...employees];
                                                                newEmployees.splice(index, 1);
                                                                setEmployees(newEmployees);
                                                            }}>X</button>
                                                        )
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>

                {/* Associazione con i coupon */}
                <RelationHandlerComponent
                    options={options.coupons}
                    setValue={setValue}
                />

                {/* Card di salvataggio */}
                <FloatingBottomToolbar onSave={() => {
                    handleSave(false);
                }} 
                onSaveAndClose={() => {
                    handleSave(true);
                }} onDiscard={() => {
                    handleDiscard();
                }} 
                isSubmitting={isSubmitting}
                />
            </div>
        </AdminLayout>
    );
};

export default SingleUser;
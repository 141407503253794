import { useEffect } from "react";
import Profile from "../Profile";
import { TextDictionary } from "../../utils/TextDictionary";

const HandleSubscriptions = () => {
    return (
        <div>
            <h1 className="text-xl font-medium text-primary">{TextDictionary.GestioneAbbonamenti.Titolo}</h1>
        </div>
    );
};

export default HandleSubscriptions;
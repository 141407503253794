import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthService } from '../auth/sso/auth.service';
import { TextDictionary } from '../utils/TextDictionary';
import { UserService } from '../services/user.service';

const DropdownUser = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(AuthService.getInstance().accessToken !== ""); // Se c'è un accessToken, l'utente è autenticato

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const trigger = useRef<any>(null);
  const dropdown = useRef<any>(null);

  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div className="relative">
      <span className="hidden text-right lg:block cursor-pointer"
        ref={trigger}
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <svg xmlns="http://www.w3.org/2000/svg" height={25} width={25} fill='white' data-name="Layer 1" viewBox="0 0 29 29" id="user"><path d="M14.5 2A12.514 12.514 0 0 0 2 14.5 12.521 12.521 0 0 0 14.5 27a12.5 12.5 0 0 0 0-25Zm7.603 19.713a8.48 8.48 0 0 0-15.199.008A10.367 10.367 0 0 1 4 14.5a10.5 10.5 0 0 1 21 0 10.368 10.368 0 0 1-2.897 7.213ZM14.5 7a4.5 4.5 0 1 0 4.5 4.5A4.5 4.5 0 0 0 14.5 7Z"></path></svg>
      </span>
      <div
        ref={dropdown}
        className={`absolute right-0 z-10 w-52 mt-6 overflow-hidden text-black bg-white rounded-md shadow-xl ${dropdownOpen ? 'block' : 'hidden'
          }`}
      >
        {
          UserService.getInstance().user && (
            <div className="px-4 py-2 text-sm text-black text-center">
              <span className="font-semibold"> {UserService.getInstance().user?.Email}</span>
            </div>
          )
        }
        <div className='border-t border-black border-opacity-10'></div>
        <Link
          to="/profile"
          className="block px-4 py-2 text-sm text-black duration-200 transform hover:bg-[#e2e2e2]"
        >
          {TextDictionary.DropdownUtente.VaiAlProfilo}
        </Link>
        {
          AuthService.getInstance().isAdmin && (
            <Link
              to="/admin/dashboard"
              className="block px-4 py-2 text-sm text-black duration-200 transform hover:bg-[#e2e2e2]"
            >
              {TextDictionary.DropdownUtente.AreaAmministrativa}
            </Link>
          )
        }
        <div className='border-t border-black border-opacity-10'></div>
        {
          // Se l'utente è autenticato, mostro il pulsante di logout
          isAuthenticated && (
            <button
              onClick={() => {
                AuthService.getInstance().signOut();
                setIsAuthenticated(false);

                // Ricarico la pagina
                window.location.reload();
              }}
              className="block w-full px-4 py-2 text-sm bg-black text-white"
            >
              {TextDictionary.DropdownUtente.Logout}
            </button>
          )
        }
      </div>
    </div>
  );
};

export default DropdownUser;

import { useEffect, useState } from "react";
import AdminLayout from "../../../layout/AdminLayout";
import { unstable_usePrompt, useLoaderData, useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import { TextDictionary } from "../../../utils/TextDictionary";
import { FloatingBottomToolbar } from "../../../components/FloatingBottomToolbar";
import { ApiConfig } from "../../../config/Configuration";
import Editor, { BtnBold, BtnItalic, EditorProvider, Toolbar } from 'react-simple-wysiwyg';
import axios from "axios";
import { RelationHandlerComponent } from "../../../components/RelationHandlerComponent";
import { Video } from "../../../models/video";
import { saveFunction } from "../../../core/saveFunction";
import { Controller, set, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { axiosAuthInstance } from "../../../auth/sso/auth.interceptor";

const SingleVideo = () => {
    const navigate = useNavigate();

    const { id } = useParams();

    const video = useLoaderData() as Video | null;

    const [data, setData] = useState<any[]>([]); // Array di oggetti con value e label per le opzioni del select

    const { register, setValue, getValues, control, handleSubmit, formState: { errors, isDirty, isSubmitting }, reset } = useForm<Video>({
        defaultValues: {
            Title: video?.Title || '',
            Description: video?.Description || '',
            ShortDescription: video?.ShortDescription || '',
            Teacher: video?.Teacher || '',
            URLVideo: video?.URLVideo || '',
            QuizID: video?.QuizID || null,
            ResourceIDs: video?.ResourceIDs || [],
            CourseIDs: video?.CourseIDs || [],
        }
    });
    const [isSaving, setIsSaving] = useState(false);
    const [toggleAlert, setToggleAlert] = useState(false);
    const [selectedQuiz, setSelectedQuiz] = useState<any | null>(null);

    const onSubmit = (data: Video, saveAndClose?: boolean) => saveFunction(data, id, "videos", "Video", setIsSaving, navigate, saveAndClose);

    const options = {
        resources: {
            endpoint: "/resources",
            element: { video },
            dictionaryContext: "Risorse",
            columsToShow: ["Description"],
            dropdownElementAttributes: ["Description"],
            idsAttribute: "ResourceIDs",
            leftLabel: TextDictionary.Admin.Corsi.RisorseAssociate,
            rightLabel: TextDictionary.Admin.Corsi.AggiungiRisorse,
            noDataMessage: TextDictionary.Admin.Corsi.NessunaRisorsa,
            gridName: "resourcesGrid"
        },
        courses: {
            endpoint: "/courses",
            element: { video },
            dictionaryContext: "Corsi",
            columsToShow: ["Title"],
            dropdownElementAttributes: ["Title"],
            idsAttribute: "CourseIDs",
            leftLabel: TextDictionary.Admin.Quiz.CorsiAssociati,
            rightLabel: TextDictionary.Admin.Quiz.AggiungiCorsi,
            noDataMessage: TextDictionary.Admin.Quiz.NessunCorso,
            gridName: "coursesGrid",
        }
    }

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            // Stampo la combinazione [campo]: [messaggio di errore] per ogni errore di validazione
            Swal.fire({
                title: TextDictionary.Admin.Salvataggio.ErroreSalvataggio,
                text: Object.keys(errors).map(key => `${key}: ${(errors as any)[key].message}`).join('\n')
            });
        }
    }, [toggleAlert]);

    useEffect(() => {
        // Scarico la lista dei quiz
        const fetchQuiz = async () => {
            const response = await axiosAuthInstance.get(`${ApiConfig.ROOT_URL}/quizzes`);
            const data = response.data;

            const options = data.map((quiz: any) => {
                return {
                    value: quiz.ID,
                    label: quiz.Title
                };
            });

            setData(options);

            if (!video) return;

            const selected = options.find((quiz: any) => quiz.value === video.QuizID);
            setSelectedQuiz(selected);
        };

        fetchQuiz();

        if (video) {
            reset({
                Title: video.Title,
                Description: video.Description,
                ShortDescription: video.ShortDescription,
                Teacher: video.Teacher,
                URLVideo: video.URLVideo,
                QuizID: video.QuizID,
                ResourceIDs: video.ResourceIDs,
                CourseIDs: video.CourseIDs,
            });
        }
    }, [video]);

    const handleChange = (selected: any) => {
        if (!selected) {
            setSelectedQuiz(null);
            setValue('QuizID', null);
            return;
        }

        setSelectedQuiz(selected);

        setValue('QuizID', +selected.value);
    };

    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            if (isDirty && !isSaving) {
                e.preventDefault();
                e.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isDirty, isSaving]);

    unstable_usePrompt({
        message: TextDictionary.Admin.Salvataggio.MessaggioModificheInSospeso,
        when: ({ currentLocation, nextLocation }) =>
            isDirty && !isSaving && currentLocation.pathname !== nextLocation.pathname
    });

    const handleDiscard = () => {
        // Mostro un avviso se il form è stato modificato
        navigate('/admin/videos');
    }

    const handleSave = (saveAndClose?: boolean) => {
        handleSubmit(() => onSubmit(getValues(), saveAndClose))()
            .then((e) => {
                setToggleAlert(!toggleAlert);
            },
                (error) => {
                    const errorMessages = [];
                    for (const key in error.response.data.errors) {
                        errorMessages.push(`${key}: ${error.response.data.errors[key]}`);
                    }
                    Swal.fire({
                        title: TextDictionary.Admin.Salvataggio.ErroreSalvataggio,
                        text: errorMessages.join('\n')
                    });
                }
            );
    }

    return (
        <AdminLayout>
            <div className="flex flex-row w-full gap-4">
                <div className="flex flex-col gap-4 flex-1 w-full">
                    <h1 className="text-2xl font-semibold mb-8">{id ? TextDictionary.Admin.Video.ModificaVideo : TextDictionary.Admin.Video.NuovoVideo}</h1>

                    <div className="flex flex-row w-full gap-8">
                        <div className="module-card flex flex-col gap-4 w-full">
                            {/* Nome */}
                            <div className="flex flex-col mb-4 w-full">
                                <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Video.CampoTitolo}*</label>
                                <div className="flex flex-col gap-4">
                                    <input type="text" id="name" className="border border-black border-opacity-10 w-full px-2 py-1"
                                        {...register('Title', { required: TextDictionary.Admin.Form.CampoObbligatorio })}
                                        autoFocus />
                                </div>
                                {errors.Title && <p role="alert" className="text-errorRed text-sm">{errors.Title.message}</p>}
                            </div>

                            {/* Descrizione */}
                            <div className="flex flex-col mb-4 w-full">
                                <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Video.CampoDescrizione}</label>
                                <Controller
                                    name="Description"
                                    control={control}
                                    render={({ field: { onChange, value, ref } }) => (
                                        <EditorProvider>
                                            <Editor
                                                onChange={(e) => onChange(e.target.value)}
                                                value={value}
                                                className="border border-black border-opacity-10 w-full resize-none px-2"
                                                containerProps={{ style: { height: '200px', width: '100%' } }}
                                                name="Description"
                                            >
                                                <Toolbar>
                                                    <BtnBold />
                                                    <BtnItalic />
                                                </Toolbar>
                                            </Editor>
                                        </EditorProvider>
                                    )}
                                />
                            </div>

                            {/* Descrizione breve */}
                            <div className="flex flex-col mb-4 w-full">
                                <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Video.CampoDescrizioneBreve}</label>
                                <Controller
                                    name="ShortDescription"
                                    control={control}
                                    render={({ field: { onChange, value, ref } }) => (
                                        <EditorProvider>
                                            <Editor
                                                onChange={(e) => onChange(e.target.value)}
                                                value={value}
                                                className="border border-black border-opacity-10 w-full resize-none px-2"
                                                containerProps={{ style: { height: '200px', width: '100%' } }}
                                                name="ShortDescription"
                                            >
                                                <Toolbar>
                                                    <BtnBold />
                                                    <BtnItalic />
                                                </Toolbar>
                                            </Editor>
                                        </EditorProvider>
                                    )}
                                />
                            </div>
                        </div>

                        <div className="flex flex-col gap-4 w-full">
                            {/* Insegnante */}
                            <div className="module-card flex flex-col mb-4 w-full">
                                <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Video.CampoAutore}</label>
                                <input type="text" id="name" className="border border-black border-opacity-10 w-full px-2 py-1"
                                    {...register('Teacher')} />
                            </div>

                            {/* Selezione quiz */}
                            <div className="module-card flex flex-col mb-4 w-full">
                                <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Video.CampoQuiz}</label>
                                <Controller
                                    name="QuizID"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            className='mb-4'
                                            options={data}
                                            value={selectedQuiz}
                                            isSearchable
                                            isClearable // Permette di svuotare il campo di ricerca
                                            onChange={handleChange}
                                            closeMenuOnSelect={true}  // Mantiene il menu aperto dopo la selezione
                                            hideSelectedOptions={false}  // Mantiene le opzioni selezionate visibili nella lista
                                            placeholder="Cerca quiz..."
                                        />
                                    )}
                                />
                            </div>

                            {/* URL del video */}
                            <div className="module-card flex flex-col w-full">
                                <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Video.CampoURL}*</label>
                                <input type="text" id="name" className="border border-black border-opacity-10 w-full px-2 py-1"
                                    {...register('URLVideo', {
                                        pattern: {
                                            // const regex = /vimeo\.com\/(?:video\/)?(\d+)/;
                                            value: /(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com\/(?:video\/)?(\d+))/,
                                            message: TextDictionary.Admin.Video.URLInseritoNonValido
                                        }
                                    })} />
                                {errors.URLVideo && <p role="alert" className="text-errorRed text-sm">{errors.URLVideo.message}</p>}
                                {
                                    // Se è stato caricato un video, mostro l'anteprima (al momento mostro solo immagine di placeholder)
                                    video?.EmbeddedHTML && (
                                        // Creo un div che abbia come innerHTML il valore dell'attributo EmbeddedHTML
                                        <div
                                            dangerouslySetInnerHTML={{ __html: video?.EmbeddedHTML || '' }}
                                            className="video-iframe-container w-full mt-4"
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    {/* Associazione con le risorse */}
                    <RelationHandlerComponent
                        options={options.resources}
                        setValue={setValue}
                    />

                    {/* Associazione con le risorse */}
                    <RelationHandlerComponent
                        options={options.courses}
                        setValue={setValue}
                    />
                </div>

                {/* Card di salvataggio */}
                <FloatingBottomToolbar onSave={() => {
                    handleSave(false);
                }} 
                onSaveAndClose={() => {
                    handleSave(true);
                }} onDiscard={() => {
                    handleDiscard();
                }} 
                isSubmitting={isSubmitting}
                />
            </div>
        </AdminLayout>
    );
};

export default SingleVideo;
import { useEffect } from "react";
import PublicLayout from "../layout/PublicLayout";
import { TextDictionary } from "../utils/TextDictionary";

const Checkout = () => {

    const SubtotalElement = () => {
        return (
            <div className="flex flex-row justify-between items-center border-b border-black border-opacity-20 p-4">
                <div className="flex flex-row gap-4 items-center">
                    <img src="https://placehold.co/400" alt="Product" className="w-8 h-8 object-cover" />
                    <p className="text-md">Nome prodotto</p>
                </div>
                <p className="text-md font-medium">499 €</p>
            </div>
        );
    }


    return (
        <PublicLayout>
            <div className="max-w-[1400px] flex flex-col w-full items-start mx-auto my-12 px-4 overflow-y-auto md:px-12"> {/* Container principale */}
                <h1 className="text-4xl font-medium mb-12 bodoni">{TextDictionary.Carrello_Checkout.TitoloCheckout}</h1>
                <div className="w-full flex flex-col gap-4 md:flex-row"> {/* Contenuto */}
                    <div className="w-full md:w-3/5 flex flex-col gap-10">
                        <div className="flex flex-col border border-black border-opacity-20 w-full">
                            {/* Header */}
                            <div className="flex flex-row items-center border-b border-black border-opacity-20 p-4">
                                <p className="text-lg font-medium">{TextDictionary.Carrello_Checkout.LabelDettagliDiFatturazione}</p>
                            </div>

                            {/* Lista dei campi */}
                            <div className="flex flex-col gap-4 p-4">
                                {/* Nome e cognome in una row */}
                                <div className="flex flex-row gap-4">
                                    <input type="text" placeholder="Nome" className="border border-black border-opacity-20 p-2 flex-1" />
                                    <input type="text" placeholder="Cognome" className="border border-black border-opacity-20 p-2 flex-1" />
                                </div>

                                {/* Nome dell'azienda */}
                                <input type="text" placeholder="Nome dell'azienda" className="border border-black border-opacity-20 p-2" />

                                {/* Paese/Regione */}
                                <input type="text" placeholder="Paese/Regione" className="border border-black border-opacity-20 p-2" />

                                {/* Indirizzo */}
                                <input type="text" placeholder="Indirizzo" className="border border-black border-opacity-20 p-2" />

                                {/* Città */}
                                <input type="text" placeholder="Città" className="border border-black border-opacity-20 p-2" />

                                {/* Provincia */}
                                <input type="text" placeholder="Provincia" className="border border-black border-opacity-20 p-2" />

                                {/* CAP */}
                                <input type="text" placeholder="CAP" className="border border-black border-opacity-20 p-2" />

                                {/* Telefono */}
                                <input type="tel" placeholder="Telefono" className="border border-black border-opacity-20 p-2" />

                                {/* Indirizzo email */}
                                <input type="email" placeholder="Indirizzo email" className="border border-black border-opacity-20 p-2" />

                                {/* Codice fiscale */}
                                <input type="text" placeholder="Codice fiscale" className="border border-black border-opacity-20 p-2" />

                                {/* Partita IVA */}
                                <input type="text" placeholder="Partita IVA" className="border border-black border-opacity-20 p-2" />

                            </div>
                        </div>

                        <div className="flex flex-col border border-black border-opacity-20 p-4 gap-4">
                            {/* Note sul tuo ordine */}
                            <p className="text-lg font-medium">{TextDictionary.Carrello_Checkout.LabelNoteSulTuoOrdine}</p>
                            <textarea placeholder="Note sul tuo ordine" className="border border-black border-opacity-20 p-2 flex-1 resize-none" rows={5} />
                        </div>
                    </div>
                    <div className="w-full md:w-2/5 h-fit border border-black border-opacity-20 flex flex-col"> {/* Sidebar */}
                        {/* Header */}
                        <div className="flex flex-row justify-between items-center border-b border-black border-opacity-20 p-4">
                            <p className="text-lg font-medium">{TextDictionary.Carrello_Checkout.LabelProdotto}</p>
                            <p className="text-lg font-medium">{TextDictionary.Carrello_Checkout.LabelSubtotale}</p>
                        </div>

                        {/* Subtotale */}
                        <SubtotalElement />
                        <SubtotalElement />
                        <SubtotalElement />
                        <SubtotalElement />

                        <div className="flex flex-row justify-between items-center border-b border-black border-opacity-20 p-4">
                            <p className="text-lg font-medium">{TextDictionary.Carrello_Checkout.LabelTotale}</p>
                            <p className="text-lg font-medium">499 €</p>
                        </div>

                        {/* Informazioni aggiuntive */}
                        <div className="flex flex-col gap-2 p-4">
                            <button className="bg-primary text-white text-lg font-medium py-2 px-8">{TextDictionary.Carrello_Checkout.BottoneEffettuaOrdine}</button>
                        </div>
                    </div>
                </div>
            </div>
        </PublicLayout>
    );
};

export default Checkout;
import { useEffect, useState } from 'react';
import './App.css';
import { Navigate, Route, Routes, createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/Home';
import Courses from './pages/Admin/Courses/Courses';
import Users from './pages/Admin/Users/Users';
import Dashboard from './pages/Admin/Dashboard';
import Coupons from './pages/Admin/Coupons/Coupons';
import Quizzes from './pages/Admin/Quizzes/Quizzes';
import SingleUser from './pages/Admin/Users/SingleUser';
import SingleCourse from './pages/Admin/Courses/SingleCourse';
import SingleQuiz from './pages/Admin/Quizzes/SingleQuiz';
import Profile from './pages/Profile';
import SubscriptionInfo from './pages/SubscriptionInfo';
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
import CoursesList from './pages/PublicCourses';
import UserCourses from './pages/Profile/UserCourses';
import UserCertificates from './pages/Profile/UserCertificates';
import HandleSubscriptions from './pages/Profile/HandleSubscriptions';
import { ApiConfig } from './config/Configuration';
import Resources from './pages/Admin/Resources/Resources';
import SingleResource from './pages/Admin/Resources/SingleResource';
import SingleCoupon from './pages/Admin/Coupons/SingleCoupon';
import Videos from './pages/Admin/Videos/Videos';
import SingleVideo from './pages/Admin/Videos/SingleVideo';
import SignIn from './pages/Authentication/Signin';
import Signup from './pages/Authentication/Signup';
import ForgotPassword from './pages/Authentication/ForgotPassword';
import ResetPassword from './pages/Authentication/ResetPassword';
import { AuthService } from './auth/sso/auth.service';
import { IDPPermission, UserService } from './services/user.service';
import { jwtDecode } from 'jwt-decode';
import { axiosAuthInstance } from './auth/sso/auth.interceptor';
import PublicCourses from './pages/PublicCourses';
import { PublicSingleCourse } from './pages/PublicSingleCourse';
import { PublicSingleVideo } from './pages/PublicSingleVideo';
import axios from 'axios';

function App() {
  useEffect(() => {
    async function handleUser() {
      await UserService.getInstance().getUserData(); // Ottengo i dati dell'utente
    }

    if (!AuthService.getInstance().accessToken)
      return;

    handleUser().then(() => {
      const decodedToken: any = jwtDecode(AuthService.getInstance().accessToken);
      // Salvo i permessi dell'utente dentro il servizio UserService, usando l'enum IDPPermission 
      UserService.getInstance().permissions = decodedToken.Permissions.map((permission: string) => {
        const enumPermission = getEnum(permission);
        if (enumPermission) {
          return enumPermission;
        } else {
          throw new Error(`Permission ${permission} not found in IDPPermission enum`);
        }
      });
    }).catch((error) => {
      console.error(error);
    });
  }, []); // Eseguo il codice ogni volta che isAuthenticated cambia

  const getEnum = (value: string): IDPPermission | undefined => {
    // Verificare se il valore è presente nell'enum
    if (Object.values(IDPPermission).includes(value as IDPPermission)) {
      return value as IDPPermission;
    } else {
      return undefined;
    }
  }

  const AdminGuard = ({ element: Component }: any) => { // Route privata per gli admin, se non hai i permessi ti reindirizza alla home
    return AuthService.getInstance().isAdmin ? <Component /> : <Navigate to="/" />;
  };

  // Route privata che verifica se l'utente è autenticato o no. Ad esempio, se provo ad accedere alla pagina del checkout senza essere autenticato,
  // mi aspetto di essere reindirizzato alla pagina di login. Se mi autentico successivamente, devo essere reindirizzato alla pagina a cui volevo accedere

  // Riscrivo la authGuard in modo da passare il path della destinazione
  const AuthGuard = ({ element: Component, to }: any) => {
    const isAuthenticated = AuthService.getInstance().accessToken ?? false;
    return isAuthenticated ? <Component /> : <Navigate to="/signin" state={{ toPath: to }} />;
  }

  // creo le routes
  const routes = createBrowserRouter([
    { path: "*", element: <Navigate to="/" /> }, // Se l'utente cerca una route non esistente, lo reindirizzo alla home

    // Gestisco le route di IDP
    { path: "/signin", element: <SignIn /> },
    { path: "/signup", element: <Signup /> },
    { path: "/forgot-password", element: <ForgotPassword /> },
    { path: "/reset-password", element: <ResetPassword /> },

    // Gestisco le route dell'applicazione
    {
      path: "/",
      element: <Home />,
      loader: async () => {
        const response = await axios.get(`${ApiConfig.ROOT_URL}/public/courses`); // Chiamo l'API per ottenere tutti i corsi prima di renderizzare la pagina
        return response.data; // Ritorno i dati dei corsi
      },
    },
    {
      path: "/profile/*", element: <Profile />, children: [
        { path: "", element: <Navigate to="/profile/courses" /> },
        { path: "courses", element: <UserCourses /> },
        { path: "certificates", element: <UserCertificates /> },
        { path: "subscriptions", element: <HandleSubscriptions /> }
      ]
    },
    { path: "/subscription-info", element: <SubscriptionInfo /> },
    { path: "/cart", element: <Cart /> },
    {
      path: "/checkout",
      element: <AuthGuard element={Checkout} to="/checkout" />,
    },
    {
      path: "/public/courses",
      element: <PublicCourses />,
      loader: async () => {
        const response = await axios.get(`${ApiConfig.ROOT_URL}/public/courses`); // Chiamo l'API per ottenere tutti i corsi prima di renderizzare la pagina
        return response.data; // Ritorno i dati dei corsi
      },
    },
    {
      path: "/public/courses/:id",
      element: <PublicSingleCourse />,
      loader: async (params: any) => {
        const response = await axios.get(`${ApiConfig.ROOT_URL}/public/courses/${params.params.id}`); // Chiamo l'API per ottenere il corso prima di renderizzare la pagina
        return response.data; // Ritorno il corso richiesto
      },
    },
    {
      path: "/public/courses/:id/videos/:videoId",
      element: <PublicSingleVideo />,
      loader: async (params: any) => {
        const response = await axiosAuthInstance.get(`${ApiConfig.ROOT_URL}/videos/${params.params.videoId}`); // Chiamo l'API per ottenere il video prima di renderizzare la pagina
        return response.data; // Ritorno il video richiesto
      }
    },
    { path: "/admin", element: <AdminGuard element={Dashboard} /> },
    { path: "/admin/dashboard", element: <AdminGuard element={Dashboard} /> },
    { path: "/admin/users", element: <AdminGuard element={Users} />, },
    {
      path: "/admin/users/:id",
      element: <AdminGuard element={SingleUser} />,
      loader: async (params: any) => {
        const response = await axiosAuthInstance.get(`${ApiConfig.ROOT_URL}/users/${params.params.id}`); // Chiamo l'API per ottenere l'utente prima di renderizzare la pagina
        return response.data; // Ritorno i dati dell'utente
      },
      errorElement: <Navigate to="/admin/users" /> // DA AGGIUNGERE A TUTTO
    },
    { path: "/admin/users/new", element: <AdminGuard element={SingleUser} /> },
    { path: "/admin/courses", element: <AdminGuard element={Courses} /> },
    {
      path: "/admin/courses/:id",
      element: <AdminGuard element={SingleCourse} />,
      loader: async (params: any) => {
        const response = await axiosAuthInstance.get(`${ApiConfig.ROOT_URL}/courses/${params.params.id}`); // Chiamo l'API per ottenere il corso prima di renderizzare la pagina
        return response.data; // Ritorno i dati
      },
      errorElement: <Navigate to="/admin/courses" /> // DA AGGIUNGERE A TUTTO 
    },
    { path: "/admin/courses/new", element: <AdminGuard element={SingleCourse} /> },
    { path: "/admin/videos", element: <AdminGuard element={Videos} /> },
    {
      path: "/admin/videos/:id",
      element: <AdminGuard element={SingleVideo} />,
      loader: async (params: any) => {
        const response = await axiosAuthInstance.get(`${ApiConfig.ROOT_URL}/videos/${params.params.id}`); // Chiamo l'API per ottenere il video prima di renderizzare la pagina
        return response.data; // Ritorno i dati del video
      },
      errorElement: <Navigate to="/admin/videos" /> // DA AGGIUNGER
    },
    { path: "/admin/videos/new", element: <AdminGuard element={SingleVideo} /> },
    { path: "/admin/quizzes", element: <AdminGuard element={Quizzes} /> },
    {
      path: "/admin/quizzes/:id",
      element: <AdminGuard element={SingleQuiz} />,
      loader: async (params: any) => {
        const response = await axiosAuthInstance.get(`${ApiConfig.ROOT_URL}/quizzes/${params.params.id}`); // Chiamo l'API per ottenere il quiz prima di renderizzare la pagina
        return response.data; // Ritorno i dati del quiz
      },
      errorElement: <Navigate to="/admin/quizzes" /> // DA AGGIUNGER
    },
    { path: "/admin/quizzes/new", element: <AdminGuard element={SingleQuiz} /> },
    { path: "/admin/resources", element: <AdminGuard element={Resources} /> },
    {
      path: "/admin/resources/:id",
      element: <AdminGuard element={SingleResource} />,
      loader: async (params: any) => {
        const response = await axiosAuthInstance.get(`${ApiConfig.ROOT_URL}/resources/${params.params.id}`); // Chiamo l'API per ottenere la risorsa prima di renderizzare la pagina
        return response.data; // Ritorno i dati della risorsa
      },
      errorElement: <Navigate to="/admin/resources" /> // DA AGGIUNGER
    },
    { path: "/admin/resources/new", element: <AdminGuard element={SingleResource} /> },
    { path: "/admin/coupons", element: <AdminGuard element={Coupons} /> },
    {
      path: "/admin/coupons/:id",
      element: <AdminGuard element={SingleCoupon} />,
      loader: async (params: any) => {
        const response = await axiosAuthInstance.get(`${ApiConfig.ROOT_URL}/coupons/${params.params.id}`); // Chiamo l'API per ottenere il coupon prima di renderizzare la pagina
        return response.data; // Ritorno i dati del coupon
      },
      errorElement: <Navigate to="/admin/coupons" /> // DA AGGIUNGER
    },
    { path: "/admin/coupons/new", element: <AdminGuard element={SingleCoupon} /> },
  ]);

  return (
    <RouterProvider router={routes} /> // Passo le routes al RouterProvider
  )
}

export default App;
import { useEffect } from "react";
import AdminLayout from "../../../layout/AdminLayout";
import { Link } from "react-router-dom";
import { TableComponent } from "../../../components/TableComponent";
import { TextDictionary } from "../../../utils/TextDictionary";


const Videos = () => {
    return (
        <AdminLayout>
            <div className="relative flex flex-col w-full h-full">
                <div className="flex flex-row justify-between w-full mb-8">
                    <h1 className="text-3xl font-semibold">{TextDictionary.Admin.Video.Titolo}</h1>
                    <button className="bg-primary text-white font-medium py-2 px-4 rounded w-40">
                        <Link to="/admin/videos/new">{TextDictionary.Admin.Video.NuovoVideo}</Link>
                    </button>
                </div>
                <TableComponent
                    endpoint="/videos"
                    dictionaryContext={"Video"}
                />
            </div>
        </AdminLayout>
    );
};

export default Videos;
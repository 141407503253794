import { ReactNode } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HeaderMobile from '../components/HeaderMobile';

interface PublicLayoutProps {
  children: ReactNode;
}

const PublicLayout = ({ children }: PublicLayoutProps) => {
  // const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="bg-white">
      <div className="flex h-screen overflow-hidden">
        {/* <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */}
        <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
          <Header isUserSetted={true} isAdminArea={false}/>
          <HeaderMobile isUserSetted={true} isAdminArea={false}/>
          <main className='grow'>
            <div className="mx-auto h-full">
              {children}
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default PublicLayout;

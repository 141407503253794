const Footer = () => {
    return (
        <footer className="relative bottom-0 flex w-full bg-primary justify-center">
            <div className="flex flex-grow items-center justify-between py-4 px-4 md:px-6 2xl:px-11 max-w-[1400px]">
                <div className="flex items-center gap-2 cursor-pointer">
                    <div className="text-white font-medium text-lg cursor-pointer flex flex-row items-center gap-2">
                        <img src="./logo.svg" alt="logo" className="h-16" />
                        Academy<span className="text-md font-light">| Carbognani</span>
                    </div>
                    <div className="text-white font-medium text-lg cursor-pointer">
                        © 2024
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
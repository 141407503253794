import { jwtDecode } from "jwt-decode";

export class AuthUtils {
    constructor() {
    }

    static isTokenExpired(token: string): boolean {
        const decodedToken: any = jwtDecode(token);
        const now = new Date();
        const exp = new Date(decodedToken.exp * 1000);
        if (now > exp) {
            return true;
        }
        return false;
    }

    static isTokenValid(token: string): boolean {
        const decodedToken: any = jwtDecode(token);
        const now = new Date();
        const exp = new Date(decodedToken.exp * 1000);

        if (now < exp) {
            return true;
        }
        return false;
    }
}
import { TextDictionary } from "../utils/TextDictionary"

interface FloatingAdminCardProps {
    onSave: () => void;
    onSaveAndClose?: () => void;
    onDiscard: () => void;
    isSubmitting: boolean;
}

export const FloatingBottomToolbar = ({ onSave, onSaveAndClose, onDiscard, isSubmitting }: FloatingAdminCardProps) => {
    return (
        <div className="flex flex-row gap-4 fixed bottom-0 right-0 bg-white p-4 shadow-lg">
            <button className={`bg-[#2d8a2b] text-white px-4 py-1 hover:bg-transparent hover:text-[#2d8a2b] border border-[#2d8a2b] ${isSubmitting ? 'opacity-50' : ''}`} onClick={onSaveAndClose} disabled={isSubmitting}>{TextDictionary.Admin.Coupons.BottoneSalvaChiudi}</button>
            <button className={`bg-[#2d8a2b] text-white px-4 py-1 hover:bg-transparent hover:text-[#2d8a2b] border border-[#2d8a2b] ${isSubmitting ? 'opacity-50' : ''}`} onClick={onSave} disabled={isSubmitting}>{TextDictionary.Admin.Coupons.BottoneSalva}</button>
            <button className={` bg-[#f5584d] text-white px-4 py-1 hover:bg-transparent hover:text-errorRed border border-[#f5584d] ${isSubmitting ? 'opacity-50' : ''}`} onClick={onDiscard} disabled={isSubmitting}>{TextDictionary.Admin.Coupons.BottoneAnnulla}</button>
        </div>
    )
}
import { DTO } from "../core/dtoInterface";

export interface Coupon extends DTO {
    CreatedAt?: Date;
    UpdatedAt?: Date;
    Title: string;
    Description: string;
    Type: CouponType;
    Value: number;
    UserIDs: number[];
    AllowedEmails: string[];
}

export enum CouponType {
    PERCENTAGE_DISCOUNT = "percentage_discount",
    FIXED_AMOUNT_DISCOUNT = "fixed_amount_discount",
}
import { useEffect, useState } from "react";
import AdminLayout from "../../../layout/AdminLayout";
import { unstable_usePrompt, useLoaderData, useLocation, useNavigate, useParams } from "react-router-dom";
import { RelationHandlerComponent } from "../../../components/RelationHandlerComponent";
import { TextDictionary } from "../../../utils/TextDictionary";
import { FloatingBottomToolbar } from "../../../components/FloatingBottomToolbar";
import NewDataRelationHandlerComponent from "../../../components/NewDataRelationHandlerComponent";
import axios from "axios";
import { ApiConfig } from "../../../config/Configuration";
import { Coupon, CouponType } from "../../../models/coupon";
import { useForm } from "react-hook-form";
import { saveFunction } from "../../../core/saveFunction";
import Swal from "sweetalert2";

const SingleCoupon = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const coupon = useLoaderData() as any | null;

    // Da sostituire con l'enum CouponType
    const { register, setValue, getValues, handleSubmit, formState: { errors, isDirty, isSubmitting }, reset } = useForm<Coupon>({
        defaultValues: {
            Title: coupon?.Title || '',
            Value: coupon?.Value || 0,
            Type: coupon?.Type || CouponType.PERCENTAGE_DISCOUNT,
            Description: coupon?.Description || '',
            UserIDs: coupon?.UserIDs || [],
            AllowedEmails: coupon?.AllowedEmails || []
        }
    });
    const [isSaving, setIsSaving] = useState(false);
    const [toggleAlert, setToggleAlert] = useState(false);
    const [newUserEmails, setNewUserEmails] = useState<string[]>([]);
    const onSubmit = (data: Coupon, saveAndClose?: boolean) => saveFunction(data, id, "coupons", "Coupon", setIsSaving, navigate, saveAndClose);

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            // Stampo la combinazione [campo]: [messaggio di errore] per ogni errore di validazione
            Swal.fire({
                title: TextDictionary.Admin.Salvataggio.ErroreSalvataggio,
                text: Object.keys(errors).map(key => `${key}: ${(errors as any)[key].message}`).join('\n')
            });
        }
    }, [toggleAlert]);

    useEffect(() => {
        if (coupon) {
            reset({
                Title: coupon.Title,
                Value: coupon.Value,
                Type: coupon.Type,
                Description: coupon.Description,
                UserIDs: coupon.UserIDs,
                AllowedEmails: coupon.AllowedEmails
            });
        }
    }, [coupon]);

    useEffect(() => {
        if (newUserEmails && newUserEmails.length > 0) {
            setValue('AllowedEmails', newUserEmails);
        }
    }, [newUserEmails]);

    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            if (isDirty && !isSaving) {
                e.preventDefault();
                e.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isDirty, isSaving]);

    unstable_usePrompt({
        message: TextDictionary.Admin.Salvataggio.MessaggioModificheInSospeso,
        when: ({ currentLocation, nextLocation }) =>
            isDirty && !isSaving && currentLocation.pathname !== nextLocation.pathname
    });

    const options = {
        users: {
            endpoint: "/users",
            element: { coupon },
            dictionaryContext: "Utenti",
            columsToShow: ["Name", "Surname", "Email"],
            dropdownElementAttributes: ["Name", "Surname"],
            idsAttribute: "UserIDs",
            leftLabel: TextDictionary.Admin.Coupons.UtentiAssociati,
            rightLabel: TextDictionary.Admin.Coupons.AggiungiUtenti,
            noDataMessage: TextDictionary.Admin.Coupons.NessunDato
        },
        newUsers: {
            leftLabel: TextDictionary.Admin.Coupons.EmailAssociate,
            rightLabel: TextDictionary.Admin.Coupons.AggiungiEmail,
            noDataMessage: TextDictionary.Admin.Coupons.NessunNuovoUtente,
            columnName: "Email"
        }
    }

    const genCasualCode = () => {
        // Caratteri alfanumerici
        // se generato con tasto max 8 caratteri
        // tutti trasformati in maiuscolo

        const randomString = Math.random().toString(36).substring(2, 10).toUpperCase();

        // Setto il valore del campo name con il codice generato
        const nameInput = document.getElementById('name') as HTMLInputElement;
        nameInput.value = randomString;

        // Aggiorno lo stato del form
        setValue('Title', randomString);
    }

    const handleDiscard = () => {
        navigate('/admin/coupons');
    }

    const handleSave = (saveAndClose?: boolean) => {
        handleSubmit(() => onSubmit(getValues(), saveAndClose))()
            .then((e) => {
                setToggleAlert(!toggleAlert);
            },
                (error) => {
                    const errorMessages = [];
                    for (const key in error.response.data.errors) {
                        errorMessages.push(`${key}: ${error.response.data.errors[key]}`);
                    }

                    Swal.fire({
                        title: TextDictionary.Admin.Salvataggio.ErroreSalvataggio,
                        text: errorMessages.join('\n')
                    });
                }
            );
    }


    return (
        <AdminLayout>
            <div className="flex flex-row w-full gap-4">
                <div className="flex flex-col gap-4 flex-1 w-full">
                    <h1 className="text-2xl font-semibold mb-8">{id ? TextDictionary.Admin.Coupons.ModificaCoupon : TextDictionary.Admin.Coupons.NuovoCoupon}</h1>

                    {/* Nome e importo */}
                    <div className="module-card flex flex-row items-start gap-4 w-full">
                        <div className="flex flex-col mb-4 w-full">
                            <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Coupons.CampoNome}*</label>
                            <div className="flex flex-col gap-4">
                                <input type="text" id="name" className="border border-black border-opacity-10 w-full px-2 py-1" autoFocus
                                    {...register('Title', { required: TextDictionary.Admin.Form.CampoObbligatorio })} />
                                <a className="text-black underline cursor-pointer" onClick={genCasualCode}>{TextDictionary.Admin.Coupons.BottoneGenera}</a>
                                {errors.Title && <span className="text-errorRed text-sm">{errors.Title.message}</span>}
                            </div>
                        </div>

                        {/* Importo */}
                        <div className="flex flex-col mb-4 w-full gap-4">
                            <label htmlFor="name" className="font-semibold text-md">{TextDictionary.Admin.Coupons.CampoImporto}*</label>
                            <div className="flex flex-row gap-4 items-center">
                                <select id="type" className="bg-white px-2 py-1 border border-black border-opacity-10 w-full"
                                    {...register('Type', { required: TextDictionary.Admin.Form.CampoObbligatorio, setValueAs: (value) => value as CouponType })}>
                                    <option value={CouponType.PERCENTAGE_DISCOUNT}>{TextDictionary.Admin.Coupons.TipologiaPercentuale}</option>
                                    <option value={CouponType.FIXED_AMOUNT_DISCOUNT}>{TextDictionary.Admin.Coupons.TipologiaFisso}</option>
                                </select>
                                <input type="number" id="value" className="border px-2 py-1 border-black border-opacity-10 w-20"
                                    {...register('Value', { required: TextDictionary.Admin.Form.CampoObbligatorio, valueAsNumber: true })} />
                                <span>{getValues('Type') === CouponType.PERCENTAGE_DISCOUNT ? '%' : '€'}</span>
                            </div>
                            {errors.Type && <span className="text-errorRed text-sm">{errors.Type.message}</span>}
                            {errors.Value && <span className="text-errorRed text-sm">{errors.Value.message}</span>}
                        </div>
                    </div>

                    {/* Descrizione */}
                    <div className="module-card flex flex-col mb-4 w-full">
                        <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Coupons.CampoDescrizione}</label>
                        <textarea className="border border-black border-opacity-10 w-full h-32 resize-none px-2"
                            {...register('Description')} />
                    </div>

                    <RelationHandlerComponent
                        options={options.users}
                        setValue={setValue}
                    />

                    <NewDataRelationHandlerComponent
                        options={options.newUsers}
                        setNewElementsData={setNewUserEmails}
                        newElementsData={getValues('AllowedEmails')}
                    />
                </div>

                {/* Card di salvataggio */}
                <FloatingBottomToolbar onSave={() => {
                    handleSave(false);
                }} 
                
                onSaveAndClose={() => {
                    handleSave(true);
                }}
                onDiscard={() => {
                    handleDiscard()
                }} 
                isSubmitting={isSubmitting}
                />
            </div>
        </AdminLayout>
    );
};

export default SingleCoupon;
import axios from "axios";
import { ApiConfig } from "../config/Configuration";
import { TextDictionary } from "../utils/TextDictionary";
import { DTO } from "./dtoInterface";
import Swal from "sweetalert2";
import { axiosAuthInstance } from "../auth/sso/auth.interceptor";

export async function saveFunction<T extends DTO>(data: T, id: string | undefined, endpoint: string, dictionaryField: string, setIsSaving: (value: boolean) => void, navigate: any, saveAndClose: boolean = false) {
    setIsSaving(true);

    const contextMessages = getDictionaryFields(dictionaryField); // Ottengo i messaggi di contesto per la risorsa dal dizionario

    let payload: FormData | T | any = null;
    let isBlob = false;

    for (const key of Object.keys(data)) {
        const keyOfData = key as keyof T;

        // Se keyOfData è un blob, creo un FormData
        if (data[keyOfData] instanceof File) {
            isBlob = true;


            payload = new FormData();
            // payload.append('file', data[keyOfData] as Blob);

            payload.append("file", data[keyOfData] as File);

            delete data[keyOfData];

            if (id) // Se è una modifica, aggiungo l'ID
                data.ID = +id;

            payload.append('json', JSON.stringify(data));
            break;
        }
    }

    if (!payload) {
        payload = data;
    }

    try {
        let response;
        if (!id) { // Fase di creazione
            response = await axiosAuthInstance.post(`${ApiConfig.ROOT_URL}/${endpoint}`, payload);
        } else { // Fase di modifica
            if (!isBlob) // Se non è un blob, aggiungo l'ID al payload
                data.ID = +id;
            response = await axiosAuthInstance.put(`${ApiConfig.ROOT_URL}/${endpoint}`, payload);
        }
        // 201 -> Created
        // 200 -> OK

        console.log(response);

        if (response.status === 201 || response.status === 200) {
            Swal.fire({
                icon: 'success',
                text: id ? contextMessages.EditElementMessage : contextMessages.NewElementMessage
            });

            if (saveAndClose)
                navigate('/admin/' + endpoint);
            else
                navigate('/admin/' + endpoint + '/' + response.data.ID);
        } else {
            throw new Error(TextDictionary.Admin.Salvataggio.ErroreSalvataggio);
        }
    } catch (error) {
        throw error;
    }
    finally {
        setIsSaving(false);
    }
}

function getDictionaryFields(dictionaryField: string) {
    const keyOfDictionary = dictionaryField as keyof typeof TextDictionary.Admin;

    const dictionaryContext = TextDictionary.Admin[keyOfDictionary]

    let newElementMessage: string = TextDictionary.Admin.Salvataggio.NuovoElemento;
    let editElementMessage: string = TextDictionary.Admin.Salvataggio.ModificaElemento;
    let errorMessage: string = TextDictionary.Admin.Salvataggio.ErroreSalvataggio;

    if (keyOfDictionary in TextDictionary.Admin) {
        const context = dictionaryContext as {
            CorrettoSalvataggio: string;
            CorrettaModifica: string;
            ErroreSalvataggio: string;
        };

        if (context.CorrettoSalvataggio) {
            newElementMessage = context.CorrettoSalvataggio;
        }

        if (context.CorrettaModifica) {
            editElementMessage = context.CorrettaModifica;
        }

        if (context.ErroreSalvataggio) {
            errorMessage = context.ErroreSalvataggio;
        }
    }

    return {
        "NewElementMessage": newElementMessage,
        "EditElementMessage": editElementMessage,
        "ErrorMessage": errorMessage
    }
}
import { useEffect } from "react";
import PublicLayout from "../layout/PublicLayout";
import { TextDictionary } from "../utils/TextDictionary";
import { useNavigate } from "react-router-dom";

const Cart = () => {    
    const navigate = useNavigate();

    const ProductCard = ({ isSubscription = false }) => {
        return (
            <div className="flex flex-row justify-between items-center p-4 border-b border-black border-opacity-20">
                <div className="flex flex-row gap-4 items-center">
                    <img src="https://placehold.co/400" alt="Product" className="w-16 h-16 object-cover" />
                    <div>
                        <div className="flex flex-row gap-8 items-center">
                            <p className="text-lg font-medium">Nome corso</p>
                            {isSubscription && <div className="bg-primary text-white text-xs font-medium py-1 px-2">{TextDictionary.Carrello_Checkout.LabelAbbonamento}</div>}
                        </div>

                        <div className="flex flex-row gap-2 items-center">
                            {/* mostro prezzo vecchio (barrato) e prezzo scontato */}
                            <p className="text-md text-gray-500 line-through">999 €</p>
                            <p className="text-md text-primary">499 €</p>
                        </div>

                        {/* Tasto per rimuovere il prodotto dal carrello */}
                        <button className="text-sm font-light underline">{TextDictionary.Carrello_Checkout.BottnoeRimuoviProdotto}</button>
                    </div>
                </div>
                <p className="text-lg font-medium text-primary">499 €</p>
            </div>
        );
    }


    return (
        <PublicLayout>
            <div className="max-w-[1400px] flex flex-col w-full items-start mx-auto my-12 px-4 overflow-y-auto md:px-12"> {/* Container principale */}
                <h1 className="text-4xl font-medium mb-12 bodoni">{TextDictionary.Carrello_Checkout.TitoloCarrello}</h1>
                <div className="w-full flex flex-col gap-4 md:flex-row"> {/* Contenuto */}
                    <div className="w-full md:w-3/5 border border-black border-opacity-20 flex flex-col"> {/* Prodotti */}
                        {/* Header */}
                        <div className="flex flex-row justify-between items-center border-b border-black border-opacity-20 p-4">
                            <p className="text-lg font-medium">{TextDictionary.Carrello_Checkout.LabelProdotto}</p>
                            <p className="text-lg font-medium">{TextDictionary.Carrello_Checkout.LabelTotale}</p>
                        </div>

                        {/* Lista prodotti, composta da un componente per ogni prodotto */}
                        <div className="flex flex-col gap-4">
                            <ProductCard isSubscription={true} />
                            <ProductCard />
                            <ProductCard />
                            <ProductCard isSubscription={true} />
                            <ProductCard />

                            <ProductCard />
                            <ProductCard />
                        </div>
                    </div>
                    <div className="w-full md:w-2/5 h-fit border border-black border-opacity-20 flex flex-col gap-4 p-4"> {/* Sidebar */}
                        <p className="text-lg font-medium">{TextDictionary.Carrello_Checkout.LabelRiepilogo}</p>

                        {/* Codice sconto */}
                        <div className="flex flex-row gap-4 pb-8">
                            <input type="text" placeholder="Codice promozionale" className="border border-black border-opacity-20 p-2 flex-1" />
                            <button className="bg-primary text-white text-lg font-medium py-2 px-8">{TextDictionary.Carrello_Checkout.BottoneApplica}</button>
                        </div>

                        {/* Subtotale */}
                        <div className="flex flex-row justify-between items-center">
                            <p className="text-lg font-medium">{TextDictionary.Carrello_Checkout.BottoneApplica}</p>
                            <p className="text-lg font-semibold">999 €</p>
                        </div>

                        {/* Divisore */}
                        <div className="border-b border-black border-opacity-20"></div>

                        {/* Spedizione */}

                        {/* Totale */}
                        <div className="flex flex-row justify-between items-center pb-8">
                            <p className="text-2xl font-medium">{TextDictionary.Carrello_Checkout.LabelTotale}</p>
                            <p className="text-2xl font-semibold">999 €</p>
                        </div>


                        {/* Vai al pagamento */}
                        <button className="bg-primary text-white text-lg font-medium py-2 px-8"
                            onClick={() => navigate('/checkout')}
                        >{TextDictionary.Carrello_Checkout.BottoneProcediPagamento}</button>

                        <div className="border-b border-black border-opacity-20"></div>

                        {/* Informazioni aggiuntive */}
                        <div className="flex flex-col gap-2">
                            <p className="text-lg font-medium mb-4 mt-2">{TextDictionary.Carrello_Checkout.LabelInformazioniAggiuntive}</p>
                            <div className="flex flex-row gap-4 items-center">
                                <img src="https://placehold.co/400" alt="Product" className="w-8 h-8 object-cover" />
                                <p>Mi piace arrampicarmi sugli alberi</p>
                            </div>

                            <div className="flex flex-row gap-4 items-center">
                                <img src="https://placehold.co/400" alt="Product" className="w-8 h-8 object-cover" />
                                <p>Piacenza è migliore di Parma</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PublicLayout>
    );
};

export default Cart;
export const TextDictionary = {
    "Home": {
        "Titolo": "Tutti i corsi",
        "CorsiEvidenza": "Corsi in evidenza",
        "ProssimiLive": "Prossimi live",
        "ScopriTuttiCorsi": "Scopri tutti i corsi",
        "NessunCorso": "Errore nel caricamento dei corsi",
    },
    "Profilo": {
        "Titolo": "Profilo personale",
        "MieiCorsi": "I miei corsi",
        "Certificati": "Certificati",
        "GestioneAbbonamenti": "Gestione abbonamenti",
    },
    "InfoAbbonamento": {
        "Titolo": "Info abbonamento",
    },
    "ListaCorsi": {
        "Titolo": "Scopri tutti \ni corsi",
        "Sottotitolo": "Corsi di formazione online \nriconosciuti a livello europeo!",
        "CreaAccountGratis": "Registrati",
        "BottoneAcquista": "Acquista",
        "ScopriCorso": "Scopri il corso",

        "LabelLezioni": "Lezioni:",
        "LabelData": "Data:",
        "LabelDurata": "Durata:",

        "LabelDescrizioneCorso": "Descrizione corso",
        "LabelCosaComprende": "Cosa comprende",
        "LabelLezioniNelCorso": "Lezioni",
    },
    "PaginaVideo": {
        "BottoneIniziaQuiz": "Inizia il quiz",
    },

    "Carrello_Checkout": {
        "TitoloCarrello": "Carrello",
        "TitoloCheckout": "Checkout",
        "LabelProdotto": "Prodotto",
        "LabelTotale": "Totale",
        "LabelRiepilogo": "Riepilogo",
        "BottoneApplica": "Applica",
        "BottnoeRimuoviProdotto": "Rimuovi prodotto",
        "LabelSubtotale": "Subtotale",
        "PlaceholderCodicePromozionale": "Codice promozionale",
        "BottoneProcediPagamento": "Procedi al pagamento",
        "LabelInformazioniAggiuntive": "Informazioni aggiuntive",
        "LabelAbbonamento": "Abbonamento",

        "LabelDettagliDiFatturazione": "Dettagli di fatturazione",
        "LabelNoteSulTuoOrdine": "Note sul tuo ordine",

        "BottoneEffettuaOrdine": "Effettua ordine",
    },
    "CorsiUtente": {
        "Titolo": "I miei corsi",
    },
    "CertificatiUtente": {
        "Titolo": "I miei certificati",
    },
    "GestioneAbbonamenti": {
        "Titolo": "Gestione abbonamenti",
    },
    "TabelleDinamiche": {
        "Errore": "Attenzione!",
        "ErroreMessaggio": "Errore nel caricamento dei dati",
        "BottoneRiprova": "Riprova",

        "ConfermaEliminazione": "Sei sicuro di voler eliminare l'elemento?",
        "ConfermaSi": "Si",
        "ConfermaOk": "Ok",
        "ConfermaNo": "Annulla",

        "NessunRisultato": "Nessun risultato",
    },
    "DropdownUtente": {
        "VaiAlProfilo": "Vai al profilo",
        "AreaAmministrativa": "Area amministrativa",
        "Logout": "Logout",
        "MessaggioLogout": "Logout effettuato correttamente",
    },

    "Header": {
        "VoceMenu": "Menu",
        "VoceCorsi": "Corsi",
        "VoceHome": "Home",
        "LabelCategorie": "Categorie",
        "VoceDogana": "Dogana",
        "VoceImportExport": "Import / Export",
        "VoceTrasporti": "Trasporti",
        "VoceVediCorsi": "Vedi tutti i corsi",
        "VoceScopriAbbonamenti": "Scopri gli abbonamenti",
        "VoceInternationalNews": "International News",
        "VoceProfilo": "Profilo",
        "VoceCarrello": "Carrello",
        "VoceRegistrati": "Registrati",
        "VoceAccedi": "Accedi",
    },

    "PagineIDP": {
        "Sottotitolo": "Carbognani Academy",
        "TitoloLogin": "Autenticazione",
        "TitoloRegistrazione": "Registrazione",
        "TitoloRecuperoPassword": "Recupero password",
        "TitoloResetPassword": "Reset password",

        "CampoNome": "Nome",
        "CampoCognome": "Cognome",
        "CampoEmail": "Email",
        "CampoPassword": "Password",

        "CampoObbligatorio": "Campo obbligatorio",

        "BottoneNonHaiAccount": "Non hai un account?",
        "BottoneRegistrati": "Registrati",
        "BottonePasswordDimenticata": "Password dimenticata?",

        "BottoneHaiAccount": "Hai già un account?",
        "BottoneAccedi": "Accedi",
    },

    "Admin": {
        "Salvataggio": {
            "NuovoElemento": "Elemento creato correttamente",
            "ModificaElemento": "Elemento modificato correttamente",
            "ErroreSalvataggio": "Errore durante il salvataggio dell'elemento",
            
            "MessaggioModificheInSospeso": "Hai delle modifiche in sospeso, sei sicuro di voler uscire?",
        },

        "Form": {
            "CampoObbligatorio": "Campo obbligatorio",
        },

        "Dropzone": {
            "Testo": "Trascina qui il file o clicca per selezionarlo (max 10 MB)",
            "ErroreDimensioni": "Attenzione, il file supera 10 MB",
        },

        "Tabelle": {
            "Utenti": {
                "Address": "Indirizzo",
                "City": "Città",
                "Company": "Azienda",
                "Country": "Paese",
                "Email": "Email",
                "Enabled": "Abilitato",
                "Group": "Gruppo",
                "ID": "ID",
                "IsEmployee": "Dipendente",
                "IsSubscribed": "Abbonato",
                "Language": "Lingua",
                "LastLogin": "Ultimo accesso",
                "Name": "Nome",
                "Phone": "Telefono",
                "Province": "Provincia",
                "Sdi": "Sdi",
                "Surname": "Cognome",
                "TaxCode": "Codice fiscale",
                "URLImage": "Immagine",
                "VatCode": "Partita IVA",
                "ZIP": "CAP"
            },
            "Corsi": {
                "ID": "ID",
                "Title": "Titolo",
                "Description": "Descrizione",
                "Date": "Data",
                "URLLive": "URL Lezione Live",
                "PreviewVideoURL": "URL Lezione Preview",
                "ShortDescription": "Descrizione breve",
                "Teacher": "Docente",
                "Language": "Lingua",
                "RegularPrice": "Prezzo",
                "DiscountPrice": "Prezzo scontato",
            },
            "Quiz": {
                "ID": "ID",
                "Title": "Titolo",
                "Description": "Descrizione"
            },
            "Video": {
                "ID": "ID",
                "Title": "Titolo",
                "ShortDescription": "Descrizione breve",
                "Description": "Descrizione",
                "URLVideo": "URL Lezione",
                "SmallThumb": "Miniatura lezione",
            },
            "Risorse": {
                "ID": "ID",
                "Title": "Titolo",
                "Description": "Descrizione",
                "URI": "URI",
            },
            "Coupon": {
                "ID": "ID",
                "Title": "Codice sconto",
                "Description": "Descrizione",
                "Value": "Importo",
            },
        },

        "Dashboard": {
            "CollegamentoUtenti": "Utenti",
            "CollegamentoCorsi": "Corsi",
            "CollegamentoRisorse": "Risorse",
            "CollegamentoQuiz": "Quiz",
            "CollegamentoCoupons": "Coupons",
            "CollegamentoVideo": "Lezioni",
        },
        "Utenti": {
            "Titolo": "Utenti",
            "NuovoUtente": "Nuovo utente",
            "ModificaUtente": "Modifica utente",
            "BottoneTornaIndietro": "Torna indietro",
            "BottoneAnnula": "Annulla",
            "BottoneSalva": "Salva",

            "CampoNome": "Nome",
            "CampoCognome": "Cognome",
            "CampoAzienda": "Azienda",
            "CampoEmail": "Email",
            "CampoTelefono": "Telefono",

            "CampoEmailProprietario": "Email proprietario",
            "CampoNomeAbbonamento": "Nome abbonamento",
            "CampoDataScadenza": "Data scadenza",
            "CampoNumeroDipendenti": "Numero dipendenti",

            "CouponAssociati": "Coupon associati",
            "AggiungiCoupon": "Aggiungi coupon",
            "NessunCoupon": "Nessun coupon collegato all'utente",

            "CorrettoSalvataggio": "Utente salvato correttamente",
            "CorrettaModifica": "Utente modificato correttamente",
            "ErroreSalvataggio": "Errore durante il salvataggio dell'utente",
        },
        "Risorse": {
            "Titolo": "Risorse",
            "NuovaRisorsa": "Nuova risorsa",
            "ModificaRisorsa": "Modifica risorsa",
            "BottoneTornaIndietro": "Torna indietro",
            "BottoneAnnula": "Annulla",
            "BottoneSalva": "Salva",

            "CampoNome": "Nome",
            "CampoDescrizione": "Descrizione",
            "CampoFile": "File collegato",

            "CorsiAssociati": "Corsi associati",
            "AggiungiCorsi": "Aggiungi corsi",
            "NessunCorso": "Nessun corso collegato alla risorsa",

            "VideoAssociati": "Lezioni associate",
            "AggiungiVideo": "Aggiungi lezioni",
            "NessunVideo": "Nessuna lezione collegato alla risorsa",

            "CorrettoSalvataggio": "Risorsa salvata correttamente",
            "CorrettaModifica": "Risorsa modificata correttamente",
            "ErroreSalvataggio": "Errore durante il salvataggio della risorsa",
        },
        "Quiz": {
            "Titolo": "Quiz",
            "NuovoQuiz": "Nuovo quiz",
            "ModificaQuiz": "Modifica quiz",
            
            "CampoNome": "Nome",
            "CampoDescrizione": "Descrizione",
            "CampoFile": "Seleziona file",
            "CampoPunteggioMinimo": "Punteggio minimo",

            "AnteprimaQuiz": "Anteprima quiz",
            "NessunQuizCaricato": "Nessun quiz caricato",

            "PlaceholderPunteggioMassimo": "Punteggio massimo",

            "CampoRispostaCorretta": "Risposta corretta",
            "CampoRispostaErrata": "Risposta errata",
            "CampoRispostaVuota": "Risposta vuota",

            "VideoAssociati": "Lezioni associate",
            "AggiungiVideo": "Aggiungi lezione",
            "NessunVideo": "Nessuna lezione collegata al quiz",

            "CorsiAssociati": "Corsi associati",
            "AggiungiCorsi": "Aggiungi corsi",
            "NessunCorso": "Nessun corso collegato al quiz",

            "CorrettoSalvataggio": "Quiz salvato correttamente",
            "CorrettaModifica": "Quiz modificato correttamente",
            "ErroreSalvataggio": "Errore durante il salvataggio del quiz",
        },
        "Video": {
            "Titolo": "Lezioni",
            "NuovoVideo": "Nuova lezione",
            "ModificaVideo": "Modifica lezione",
            "BottoneTornaIndietro": "Torna indietro",
            "BottoneAnnula": "Annulla",
            "BottoneSalva": "Salva",

            "CampoTitolo": "Titolo",
            "CampoDescrizione": "Descrizione",
            "CampoDescrizioneBreve": "Descrizione breve",
            "CampoURL": "URL della lezione",
            "URLInseritoNonValido": "URL inserito non valido",


            "CampoQuiz": "Quiz associato",
            "CampoAutore": "Docente",

            "CorsiAssociati": "Corsi associati",
            "AggiungiCorsi": "Aggiungi corsi",
            "NessunCorso": "Nessun corso collegato alla lezione",

            "QuizAssociati": "Quiz associato",
            "AggiungiQuiz": "Aggiungi quiz",
            "NessunQuiz": "Nessun quiz collegato alla lezione",

            "RisorseAssociati": "Risorse associate",
            "AggiungiRisorse": "Aggiungi risorse",
            "NessunaRisorsa": "Nessuna risorsa collegata alla lezione",

            "Autore": "Docente",
            "DataCreazione": "Data creazione",
            "DataModifica": "Ultima modifica",

            "CorrettoSalvataggio": "Lezione salvata correttamente",
            "CorrettaModifica": "Lezione modificata correttamente",
            "ErroreSalvataggio": "Errore durante il salvataggio della lezione",
        },
        "Corsi": {
            "Titolo": "Corsi",
            "NuovoCorso": "Nuovo corso",
            "ModificaCorso": "Modifica corso",
            "BottoneTornaIndietro": "Torna indietro",
            "BottoneAnnula": "Annulla",
            "BottoneSalva": "Salva",

            "CampoTitolo": "Nome",
            "CampoDescrizione": "Descrizione",
            "CampoDescrizioneBreve": "Descrizione breve",
            "CampoData": "Data",
            "CampoURLLive": "URL Lezione Live",
            "CampoPreview": "URL Lezione Preview",
            "CampoDocente": "Docente",
            "CampoLingua": "Lingua",
            "CampoPrezzo": "Prezzo",
            "CampoPrezzoScontato": "Prezzo scontato",
            "CampoImmagine": "Immagine",
            "CliccaPerCaricareImmagine": "Clicca per caricare l'immagine (max 10 MB)",
            "RimuoviImmagine": "Rimuovi immagine",

            "VideoAssociati": "Lezioni associate",
            "AggiungiVideo": "Aggiungi lezione",
            "NessunVideo": "Nessuna lezione collegata al corso",

            "RisorseAssociate": "Risorse associate",
            "AggiungiRisorse": "Aggiungi risorse",
            "NessunaRisorsa": "Nessuna risorsa collegata al corso",

            "QuizAssociato": "Quiz associato",

            "CorrettoSalvataggio": "Corso salvato correttamente",
            "CorrettaModifica": "Corso modificato correttamente",
            "ErroreSalvataggio": "Errore durante il salvataggio del corso",

            "ImmagineSuperaDimensioni": "Attenzione, l'immagine supera 10 MB",
        },
        "Coupons": {
            "Titolo": "Coupons",
            "NuovoCoupon": "Nuovo coupon",
            "ModificaCoupon": "Modifica coupon",
            "BottoneTornaIndietro": "Torna indietro",

            "CampoNome": "Codice sconto",
            "CampoDescrizione": "Descrizione",
            "CampoImporto": "Importo del codice promozionale",
            "BottoneGenera": "Genera codice casuale",
            "TipologiaPercentuale": "Percentuale",
            "TipologiaFisso": "Fisso",
            "UtentiAssociati": "Utenti associati",
            "AggiungiUtenti": "Aggiungi utenti",
            "EmailAssociate": "Email associate",
            "AggiungiEmail": "Aggiungi email",

            "NessunDato": "Coupon valido per tutti gli utenti",
            "NessunNuovoUtente": "Nessun nuovo utente da associare",
            "Autore": "Autore",
            "DataCreazione": "Data creazione",
            "DataModifica": "Ultima modifica",

            "BottoneSalva": "Salva",
            "BottoneAnnulla": "Annulla",
            "BottoneSalvaChiudi": "Salva e chiudi",

            "PlaceholderAutore": "Admin",
            "PlaceholderData": "gg/mm/aa",

            "CorrettoSalvataggio": "Coupon salvato correttamente",
            "CorrettaModifica": "Coupon modificato correttamente",
            "ErroreSalvataggio": "Errore durante il salvataggio del coupon",
        }
    },
    "Autenticazione": {
        "Login": {
            "Titolo": "Accedi",
        },
        "Registrazione": {
            "Titolo": "Registrati",
        },
        "RecuperoPassword": {
            "Titolo": "Recupero password",
        },
        "ResetPassword": {
            "Titolo": "Reset password",
        },
    }
}